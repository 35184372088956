.reserva_list{
    color: var(--brand);
    width: 100%;
    overflow-x: hidden;
}
.list_title{
    width: 100%;
    display: flex;
    height: 80px;
    background-color: white;
}
.top_title{
    font-size: 1.8rem;
    color: var(--brand);
    font-weight: 600;
    align-self: center;
    text-transform: uppercase;
    margin-left: 3%;
}
.list{
    width: 100%;
}
.list_prox{
    width: 100%;
    height: 40px;
    background-color: #00000010;
    display: flex;
}
.list_prox_text{
    color: #00000050;
    font-style: italic;
    align-self: center;
    margin-left: 10px;
}
.item_wrapper{
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    border-bottom: 2px solid var(--grey);
    z-index: 2;
    position: relative;
}
.item_wrapper:hover{
    border-bottom: none;
}
.item_top{
    height: 30px;
    width: 100%;
    padding: 0 5px;
    border-radius: 10px;
}
.item{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 120px;
    justify-content: space-between;
    background-color: white;
}
.item:hover{
    border-bottom: 2px solid;

}

.item_none{
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    justify-content: center;
}
.item_left{
    display: flex;
    height: 100px;
    margin-left: 10px;
    align-self: center;
}
.item_id{
    font-size: 0.6rem;
    opacity: 0.3;
    font-style: italic;
}
.item_img{
    width: 120px;
    height: 100px;
    margin-left: 10px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.item_no_img{
    padding: 20px;
    width: 80px  !important;
    height: 60px  !important;
    margin-left: 10px;
}
.item_right{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    justify-content: space-between;
    height: 100px;
}
.top_left_indicator_more{
    display: flex;
    align-items: center;
}
.more:hover{
    color: var(--action);
}
.remove_div{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: #000000da;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom: 2px solid transparent;

}
.center_text_div{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 0.8rem;
}
.button_eliminate{
    width: 50%;
    margin: 0 auto;
    border-radius: 5px;
    border: 1px solid var(--action);
    text-align: center;
    padding: 5px 0;
    cursor: pointer;
    color: white;
}
.button_cancel{
    width: 50%;
    margin: 5px auto;
    border-radius: 5px;
    background-color: var(--brand);
    color: white;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    box-sizing: border-box;
}
.button_cancel:hover{
    background-color: #000000;
}
.button_eliminate:hover{
    color: white;
    background-color: var(--action);
}
.item_flex_indicator{
    display: flex;
    height: 25px;
    align-self: center;
    align-items: center;
    border-radius: 5px;
    padding: 0 5px;
}
.item_flex{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.item_indicator{
    background-color: white;
    border-radius: 10px;
    height: 60%;
    width: 5px;
}
.item_title_div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    margin-left: 20px;
}
.item_title{
    font-weight: 600;
    font-size: 1.2rem;
}
.item_time{
    font-weight: 400;
    font-size: 0.8rem;
    opacity: 0.5;
    align-self: flex-end;
}
.item_type_tbd{
    margin-left: 5px;
    color: #00000080;
    font-weight: 500;
    font-style: italic;
}

.item_type{
    margin-left: 5px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
}
.item_flex_worker{
    width: 20px;
    height: 20px;
}
.item_flex_worker_text{
    text-transform: uppercase;
    align-self: flex-end;
}
.item_flex_symbol{
    width: 25px;
    height: 25px;
    color: var(--action);
}
.item_desc{
    width: 250px; 
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.8rem;
    font-weight: 400;
    white-space: pre-line;
}
.text_tbd{
    font-style: italic;
    margin-left: 5px;
}
.request_date{
    align-self: flex-end;
    color: #00000050;
    font-style: italic;
}
.dropdown_top{
    background-color: white;
    width: 100%;
    padding: 5px 2px;
    box-sizing: border-box;
}
.dropdown_top_text{
    color: #ccc;
    font-style: italic;
    padding: 5px;
}
.drop_div_symbol{
    font-size: 1rem;
}
.drop_div_main{
    width: 100%;
    cursor: pointer;
    padding: 5px 0;
}
.drop_div_main:hover{
    background-color: var(--action);

}
.drop_div_main:hover{
    color: white;
}
.drop_div{
    display: flex;
    justify-content: flex-start;
    width: 155px;
    margin: auto;
    padding: 5px;
    align-items: center;
}
.publicar{
    background-color: var(--brand);
    padding: 7px;
    box-sizing: border-box;
    color: white;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    border: 3px solid var(--brand);
    text-align: center;
    margin-top: 10px;
}
.publicar:hover{
    background-color: white;
    transition: 150ms;
    color: var(--brand);
}
.item_middle{
    display: flex;
}
.item_middle_accept{
    padding: 5px 10px;
    background-color: var(--check);
    cursor: pointer;
    margin-right: 10px;
    color: white;
    border-radius: 5px;
}
.item_middle_accept:hover{
    opacity: 0.8;
}

.item_middle_deny{
    padding: 5px 10px;
    background-color: var(--red);
    cursor: pointer;
    margin-right: 10px;
    color: white;
    border-radius: 5px;
}
.item_middle_deny:hover{
    opacity: 0.8;
}

.item_middle_stop{
    padding: 5px 10px;
    background-color: var(--yellow);
    cursor: pointer;
    margin-right: 10px;
    color: white;
    border-radius: 5px;
}
.item_middle_stop:hover{
    opacity: 0.8;
}

@media (max-width: 1024px){
    .top_title{
        font-size: 1.4rem;
    }
    .item_wrapper{
        font-size: 0.8rem;
    }
    .item_title{
        font-size: 1rem;
    }
}
@media (max-width: 924px){
    .item_title{
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 350px;
        white-space: nowrap;
    }
}
@media (max-width: 850px){
    .item_wrapper{
        font-size: 0.6rem;
    }
}
@media (max-width: 768px){
    .item_title{
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 250px;
        white-space: nowrap;
    }
}