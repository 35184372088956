@import '../index.css';

.box{
    background-color: white;
    align-self: center;
    margin: auto;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    border-bottom: 3px solid var(--brand);
    box-sizing: border-box;
    padding: 10px;
    border-bottom: 3px solid var(--action);
}
.box:last-child{
    padding-bottom: 10px;
}
.box:hover{
    border-bottom: 6px solid var(--action);
    transform: scale(1.01);
    transition: all 150ms;
}
.box_underline{
    
}
.image_tbd_wrapper{
    /* background-color: var(--brand); */
    /* border: 1px solid var(--action); */
    border-radius: 5px;
    width: 80px;
    height: 80px;
}
.image_tbd{
    color: var(--brand);
    height: 100% !important;
    width: 100% !important;
}
.box_mask{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.own{
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--action);
    padding: 5px;
    border-radius: 3px;
}
.own_text{
    font-size: 0.8rem;
    color: white;
    font-weight: 600;
}
.top_flex{
    display: flex;
    align-items: flex-start;
}
.worker_img{
    height: 80px;
    min-width: 80px;
    border-radius: 5px;
    object-fit: cover;
}
.worker_info_div{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 5px;
}
.worker_info_name{
    font-weight: 500;
    font-size: 1.2rem;
}
.worker_info_type{
    font-size: 0.8rem;
    color: #ccc;
}
.middle{}
.top_desc{
    width: 100%; 
    max-width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.8rem;
    font-weight: 400;
    white-space: pre-line;
    text-align: left;
    color: var(--grey);
    align-self: flex-end;
    max-height: 60px;
    margin-left: 10px;
    margin-top: 10px;
}
.bottom{
    margin-top: 5px;
}
.bottom_div{
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-top: 4px;
}
.bottom_div_icon{
    width: 15px !important;
    height: 15px !important;
    /* color: var(--neutral); */
}
.bottom_div_icon_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 3px;
}
.bottom_div_text{
    font-size: 0.8rem;
    color: var(--grey);
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 500px;
    overflow: hidden;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 4px;
}
.options{
    font-weight: 600;
    font-size: 0.8rem;
    color: var(--brand);
}
.options_selected{
    color: var(--action);
    font-weight: 600;
    font-size: 0.8rem;
}
.top_image_div{
    margin: 0 0px;
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
}
.top_image_div_selected{
    margin: 0 0px;
    background-color: var(--action);
    padding: 5px;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.top_image{
    width: 20px;
    height: 20px;
}
.middle_images{
    width: 100%;
}
.middle_images_background{
    display: flex;
    width: fit-content;
    /* background-color: var(--brand); */
    /* padding: 5px; */
    border-radius: 5px;
    align-items: center;
    justify-content: space-around;
    margin-left: 10px;
}

@media (max-width: 1200px){
    .bottom_div_text{
        max-width: 430px;
    }
}
@media (max-width: 768px){
    .bottom{
        max-width: 300px;
    }
    .worker_info_name{
        font-size: 1rem;
    }
    .image_tbd_wrapper{
        width: 50px;
        height: 50px;
    }
    .top_image{
        width: 15px;
        height: 15px;
    }
    .options{
        font-size: 0.7rem;
    }
    .options_selected{
        font-size: 0.7rem;
    }
    .bottom_div_icon{
        width: 15px !important;
        height: 15px !important;
    }
    .worker_img{
        width: 50px;
        /* height: 50px; */
    }
}


@media (max-width: 500px){
    .bottom{
        max-width: 300px;
    }
    .worker_info_name{
        font-size: 0.8rem;
    }
    .image_tbd_wrapper{
        width: 30px;
        height: 30px;
    }
    .top_image{
        width: 10px;
        height: 10px;
    }
    .top_image_div_selected{
        padding: 2px;
    }
    .top_desc{
        font-size: 0.7rem;
        -webkit-line-clamp: 3;
    }
    .top_image_div{
        padding: 2px;
    }
    .options{
        font-size: 0.5rem;
    }
    .options_selected{
        font-size: 0.5rem;
    }
    .bottom_div_icon{
        width: 8px !important;
        height: 8px !important;
    }
    .worker_img{
        width: 30px;
        /* height: 30px; */
    }
}