.personal{
    color: var(--brand);
    height: 100%;
    width: 100%;
}
.backdrop{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000aa;
    z-index: 1;
    transition: all 500ms ease-in-out;
}
.personal_title{
    width: 100%;
    display: flex;
    height: 80px;
    /* background-color: white; */
    margin-top: 1px;
    justify-content: center;
    border-bottom: 1px solid white;
}
.top_info{
    height: fit-content;
    transition: all 150ms;
    cursor: pointer;
    background-color: var(--brand);
    padding: 0px 0 10px 0;
}
.top_separator{
    border-radius: 20px;
    height: 2px;
    width: 100%;
    background: var(--yellow);
    transition: 150ms;
}
.top_separator_incomplete{
    border-radius: 20px;
    height: 2px;
    width: 100%;
    background: linear-gradient(270deg, rgba(3,88,229,1) 0%, rgba(253,216,53,1) 100%);
    transition: 150ms;
}



.top_separator_complete{
    border-radius: 20px;
    height: 2px;
    width: 100%;
    background: var(--job);
    transition: 150ms;
}
.top_title{
    font-size: 1.6rem;
    color: var(--neutral);
    font-weight: 600;
    align-self: center;
    text-transform: uppercase;
    margin-left: 10px;
}
.top_title_two{
    font-size: 3rem;
    color: var(--action);
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.3;
    margin-bottom: 20px;
}
.top_wrap_incomplete{
    background-color: var(--yellow);
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    animation-name: yellow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.top_wrap_complete{
    background-color: var(--check);
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.top_complete{
    color: white;
    font-size: 0.9rem;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
}
.top_complete_arrow{
    width: 20px !important;
    height: 20px !important;
    transition: all 150ms;
    color: white;
}
.top_complete_arrow_show{
    width: 20px !important;
    height: 20px !important;
    transform: rotate(90deg);
    transition: all 150ms;
    color: white;
}
.action{
    color: var(--action);
}
.mid{
    margin: auto;
    padding: 20px;
    padding-bottom: 0;
    padding-top: 20px;
    width: 100%;
    background-color: var(--brand);
    box-sizing: border-box;
    border-bottom: 1px solid var(--brand);
    position: relative;
    overflow-x: scroll !important;
    overflow-y: auto !important;
    height: 100vh;
}
.top_complete_line{
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.top_complete_line:first-child{
    margin-top: 0px;
}

.line_icon{
    width: 15px !important;
    height: 15px !important;
    color: var(--neutral);
}
.line_icon_complete{
    width: 15px !important;
    height: 15px !important;
    color: var(--brand);
}
.line_text{
    margin-left: 5px;
    color: var(--brand);
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
}
.line_val{
    width: 18px !important;
    height: 18px !important;
    margin-left: 5px;
    color: var(--brand);
}
.line_text_complete{
    color: var(--neutral);
    margin-left: 5px;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
}
.line_val_complete{
    width: 18px !important;
    height: 18px !important;
    color: var(--neutral);
    margin-left: 5px;
}
.top_desc{
    font-size: 0.8rem;
    margin-bottom: 20px;
    display: inline-block;
    color: white;
}
.divider_max{
    height: 1px;
    width: 100%;
    margin: 10px auto;
    background-color: var(--grey);
}
.special{
    text-decoration: underline;
}
.flex{
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 150px;
    /* border-bottom: 1px solid white; */
}
.top_right_flex{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
}

.top_right{
    position: relative;
    width: 100%;
}
.image_wrapper{
    height: 250px;
    width: 250px;
    border: 1px solid var(--grey);
    display: flex;
    position: relative;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #ffffff10;
}
.image_wrapper:hover{
    opacity: 0.7;
    transition: 150ms all;
}
.edit_icon{
    position: absolute;
    right: 0;
    top: 0;
    color: var(--neutral);
    font-size: 30px !important;
    cursor: pointer !important;
    padding: 5px;
    background-color: var(--action);
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
}
.image_input_wrapper{
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.image_input_wrapper:hover > .edit_icon{
    opacity: 0.8;
    cursor: pointer;
    transition: all 150ms ease-in-out;
}
.edit_icon_value{
    cursor: pointer;
}
input[type=file]{
    opacity:0;
    position:absolute;
    top:0;
    right:0;
    width: 250px;
    height: 250px;
}
.image{
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
}
.image_tbd{
    height: 80% !important;
    width: 80% !important;
    align-self: center;
    margin: auto;
}
.input_flex_edit{
    height: calc(100%);
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 10px;
    padding-right: 0px;
    background-color: var(--brand);
}
.input_flex{
    height: calc(100%);
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 10px;
    padding-right: 0px;
    background-color: var(--brand);
}
.personal_subtitle{
    font-weight: 500;
    font-size: 1.1rem;
    text-transform: uppercase;
    display: inline-block;
    color: white;
    align-self: flex-start;
}
.input_edit_wrapper{
    position: absolute;
    right: 0;
    top: 0;
}
.input_div{
    display: flex;
    flex-direction: column;
    width: 350px;
}
.input_input{
    border: none;
    color: var(--grey);
    font-size: 0.95rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    background-color: var(--brand);
    outline: none;
}
.input_div_button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.input_div_button_text{
    color: white;
    font-size: 0.9rem;
    background: var(--yellow);
    padding: 2px 5px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 25px;
    font-weight: 600;
    background-color: var(--yellow);
    animation-name: yellow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.input_div_button:hover{
    opacity: 0.8;
    transition: all 150ms ease-in-out;
}
.textarea_wrong{
    color: var(--brand);
    width: 450px;
    height: 150px;
    padding-left: 5px;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    background-color: var(--background);
    border: none;
    border-bottom: 2px solid var(--red);
    outline: none;
}
.textarea_input{
    border: none;
    border-bottom: 1px solid var(--grey);
    color: var(--grey);
    width: 100%;
    max-width: 800px;
    height: 150px;
    padding: 5px;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    background-color: var(--grey)ccc10;
    display: block;
    border-radius: 5px;
}
.textarea_input_edit{
    border: none;
    border-bottom: 1px solid var(--grey);
    color: var(--brand);
    width: 100%;
    max-width: 800px;
    height: 150px;
    padding-left: 5px;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    background-color: var(--background);
    border-bottom: 2px solid var(--brand);
    outline: none;
    border: 1px solid var(--action);
}
.textarea_input_edit:focus{
    border-bottom: 2px solid var(--action);
}
.textarea_input::placeholder{
    color: var(--grey);
}
.textarea_input_edit::placeholder{
    color: var(--grey);
}
.input_div_wrapper{
    display: flex;
    align-items: center;
    margin-top: 2px;
}
.input_div_wrapper_editable{
    display: flex;
    align-items: center;
    border: 1px solid white;
    border-radius: 3px;
    padding: 8px 5px;
    padding-right: 0px;
    width: 100%;
    transition: all 150ms ease-in-out;
    box-sizing: border-box;
}
.input_div_top{
    display: flex;
}
.top_edit_area{
    margin-top: 15px;
}
.edit_area_left{
    display: flex;
    flex-direction: column;
}
.edit_area_right{
    display: flex;
    flex-direction: column;
    /* height: 150px; */
}
.input_icon_div{
    width: 30px;
    display: flex;
    align-items: center;
}
.input_icon_seperator{
    width: 1px;
    height: 100%;
    background-color: var(--job);
    margin-right: 10px;
    transition: all 150ms ease-in-out;
}
.input_icon{
    width: 20px !important;
    height: 20px !important;
    margin-right: 5px;
    color: var(--neutral);
    transition: all 150ms ease-in-out;
}
.input_title{
    font-weight: 400;
    font-size: 0.9rem;
    text-transform: uppercase;
    width: 110px;
    color: var(--neutral);
    width: max-content;
}
.input_email{
    font-weight: 400;
    color: var(--grey);
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}
.title_flex{
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
}
.edit{
    padding: 20px 0px;
    font-size: 1.2rem;
    width: 300px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: var(--action);
    color: white;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 30px;
    z-index: 10;
    left: calc(50% - 50px);
}
.edit:hover{
    opacity: 0.8;
    transition: all 150ms;
}
.save{
    padding: 20px 0px;
    font-size: 1.2rem;
    width: 300px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: var(--neutral);
    color: var(--action);
    cursor: pointer;
    font-weight: 600;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 30px;
    z-index: 10;
    left: calc(50% - 50px);
}
.save:hover{
    opacity: 0.8;
    transition: all 150ms;
}
.edit_top{
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: var(--action);
    color: white;
    cursor: pointer;
    font-weight: 600;
}
.edit_top:hover{
    opacity: 0.8;
    transition: all 150ms ease-in-out;
}
.edit_top_worker{
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: var(--action);
    color: white;
    cursor: pointer;
    font-weight: 600;
}
.edit_top_worker:hover{
    opacity: 0.8;
    transition: all 150ms ease-in-out;
}
.save_top{
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;
    color: var(--action);
    font-weight: 600;
    cursor: pointer;
}
.save_top:hover{
    opacity: 0.8;
    transition: all 150ms ease-in-out;
}
.save_top_worker{
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;
    color: var(--action);
    font-weight: 600;
    cursor: pointer;
}
.save_top_worker:hover{
    opacity: 0.8;
    transition: all 150ms ease-in-out;
}
.helper{
    color: var(--yellow);
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: -100px;
}
.divider{
    height: 2px;
    width: 100%;
    margin: 10px 0;
    background-color: var(--grey);
}
.divider_active{
    height: 2px;
    width: 100%;
    margin: 10px 0;
    background-color: var(--job);
}
.subcontainer{
    margin-left: 20px;
}

.radio_area{
    display: flex;
    align-items: center;
}
.flex_left{
    width: 48%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.flex_title{
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    /* height: 18px; */
    display: block;
    /* text-align: center; */
    width: 100%;
    padding: 5px 0;
    /* border-radius: 5px; */
    /* background-color: var(--neutral); */
    color: white;
    text-decoration: underline;
    text-underline-offset: 2px;
    /* border-bottom: 1px solid var(--neutral); */
}
.checkbox_submap{
    color: var(--neutral);
    font-size: 1rem;
    width: max-content;
    display: block;
}
.checkbox_submap_disabled{
    color: var(--grey);
    font-size: 1rem;
    width: max-content;
    display: block;
}
.worker_area{
    height: 750px;
    margin-bottom: 280px;
}
.flex_bottom{
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: space-between;
    position: relative;
    height: 100%;
    padding-bottom: 50px;
}
.area_flex{
    height: fit-content;
    display: block;
    margin-top: 40px;
}
.area{
    width: 100%;
    margin: auto;
    margin-top: 70px;
    display: flex;
}
.area_left{
    width: 30%;
    margin-right: 5px;
    margin-top: -42px;
}
.area_left_title_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 1.1rem;
}
.area_left_title{
    text-align: center;
    color: var(--neutral);
    font-weight: 400;
    text-transform: uppercase;
}
.selected_number{
    font-weight: 400;
    font-family: 'Roboto Mono', monospace;
    color: var(--neutral);
}
.area_left_map{
    /* background-color: #FF785A80; */
    border: 2px solid var(--action);
    border-radius: 5px;
    max-height: 450px;
    overflow-y: auto;
    margin-top: 3px;
    /* border-top-left-radius: 0;
    border-top-right-radius: 0; */
}
.area_right{
    width: 70%;
    position: relative;
    margin-left: 5px;
    display: block;
}
.area_right_two{
    width: 70%;
    position: relative;
    margin-left: 5px;
    display: block;
    margin-top: -30px;
}
.map_label{
    text-transform: uppercase;
    color: var(--neutral);
    margin: 10px auto;
    text-align: center;
    font-weight: 600;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.map_label:hover{
    opacity: 0.5;
    transition: 150ms all;
}
.map_label_no{
    color: var(--neutral);
    margin: 10px auto;
    text-align: center;
    font-weight: 500;
    font-style: italic;
}
.flex_select_div{
    width: 100%;
    overflow-y: auto;
    margin-bottom: 250px;
    max-height: 450px;
    display: block;
    border: 1px solid #ffffff40;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
}
/* Customize the label (the container) */
.container {
    display: flex;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1.2rem;
    align-items: center;
    height: 25px;
    margin-bottom: 10px;
}
.checkmark_image{
    width: 25px !important;
    height: 25px !important;
    margin-right: 5px;
    margin-left: 30px;
}
.checkmark_image_disabled{
    width: 25px !important;
    height: 25px !important;
    margin-right: 5px;
    margin-left: 30px;
    opacity: 0.4;
}
/* Hide the browser's default checkbox */
.container input {
position: absolute;
opacity: 0;
height: 0;
width: 0;
}

/* Create a custom checkbox */
.checkmark {
position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
background-color: var(--neutral);
border-radius: 3px;
}
.checkmark_disabled{
position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
background-color: var(--grey);
border-radius: 3px;
}
.checkbox_text{
    font-size: 1rem;
    color: white;
    width: max-content;
    display: block;
}
.checkbox_text_disabled{
    color: var(--grey);
    font-size: 1rem;
    width: max-content;
    display: block;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
background-color: var(--grey);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
background-color: var(--action);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
left: 9px;
top: 5px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

    
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark_disabled {
background-color: #FF785A90;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark_disabled:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark_disabled:after {
display: block;
}

/* Style the checkmark/indicator */
.container .checkmark_disabled:after {
left: 9px;
top: 5px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.shake{
    animation: shake_helper 1s infinite;
}

.input_wrapper{
    width: 100%;
}
.input_list{
    width: 90%;
    outline: none;
    height: 30px;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    padding-left: 5px;
    max-width: 350px;
    border-radius: 4px;
}
.input_list::placeholder{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
}
.input_list:disabled{
    background-color: var(--grey);
}
.input_list:disabled::placeholder{
    color: #ffffff30 !important;
    font-weight: 400;
}

@keyframes shake_helper {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

.radio_div{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}
.radio_text{
    margin-left: 30px;
    font-size: 1rem;
    color: white;
}
.radio_text_disabled{
    margin-left: 30px;
    font-size: 1.2rem;
    color: var(--grey);
}
.radio_button_input{
    height: 25px;
    padding-left: 5px;
    margin-left: 5px;
    font-size: 1rem;
    font-weight: 400;
    background-color: var(--white);
    color: var(--brand);
    border: none;
    border-bottom: 2px solid var(--action);
    outline: none;
    width: fit-content;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    background-color: var(--grey)ccc10;
    font-style: normal !important;
}
.radio_button_input:disabled{
    color: rgb(148, 148, 148);
}
/* Customize the label (the container) */
.radio_area {
    display: flex;
    position: relative;
    height: 20px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 10px 0;
    align-items: center;
  }
  
  /* Hide the browser's default radio button */
  .radio_area input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .fake_radio_button {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--background);
    border-radius: 50%;
  }

  .fake_radio_button_disabled{
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--background);
    border-radius: 50%;
    }
  
  /* On mouse-over, add a grey background color */
  .radio_area:hover input ~ .fake_radio_button {
    background-color: var(--grey);
  }

  
  /* When the radio button is checked, add a blue background */
  .radio_area input:checked ~ .fake_radio_button {
    background-color: var(--action);
  }

    /* When the radio button is checked, add a blue background */
    .radio_area input:checked ~ .fake_radio_button_disabled {
        background-color: #0358e590;
      }
      
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .fake_radio_button:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio_area input:checked ~ .fake_radio_button:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radio_area .fake_radio_button:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
.status_div{
    height: fit-content;
    color: white;
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff10;
}
.status_div_title{
    font-size: 0.8rem;
    text-transform: uppercase;
    margin: auto;
    font-weight: 500;
}
.status_div_flex{
    display: flex;
    align-items: center;
    margin: auto;
}
.status_div_flex_title{
    font-weight: 600;
    font-size: 1.1rem;
}
.status_icon{
    color: white;
    margin-left: 5px;
}
.status_icon_rotate{
    color: white;
    margin-left: 5px;
    animation: rotation 2s infinite linear;
}

.recaptcha_container{
    position: absolute;
    top: 0;
    left: 0;
}

.delete_account_wrapper{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    background-color: var(--neutral);
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 230px;
}
.delete_account_wrapper:hover{
    opacity: 0.7;
    transition: 150ms all;
    background-color: var(--red);
}
.delete_account_button{
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--brand);
}
.delete_account_divider{
    display: none;
    /* height: 2px; */
    width: 100%;
    background-color: var(--neutral);
}
.delete_account_divider_always{
    /* height: 2px; */
    width: 100%;
    background-color: var(--neutral);
    margin: 10px 0 20px 0;
}
.verificar_top_no_animation{
    width: 100%;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 3px 0;
    margin-bottom: 5px;
    background-color: var(--grey);
}
.verificar_top{
    width: 100%;
    background-color: var(--yellow);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 3px 0;
    margin-bottom: 5px;
    background-color: var(--yellow);
    animation-name: yellow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.input_div_button_text_no_animation{
    color: white;
    font-size: 0.9rem;
    background: var(--yellow);
    padding: 2px 5px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 25px;
    font-weight: 600;
}

.details{
    padding: 20px;
    overflow-y: auto;
    height: calc(100vh - 238px);
    padding-bottom: 150px;
}

@keyframes yellow {
    0% {
      background-color: #fdd835;
    }
    50% {
        background-color: #fdd83540;
    }
    100% {
        background-color: #fdd835;
    }
}
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}

@media (max-width: 1024px){
    .area{
        flex-direction: column;
        width: 100%;
        margin-top: 60px;
    }
    .area_left{
        width: 100%;
        margin-right: 0;
    }
    .area_right{
        width: 100%;
        margin-left: 0;
        margin-top: 50px;
    }
    .area_right_two{
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
    .area_left_title_wrapper{
        font-size: 0.9rem;
    }
    .area_left_map{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow-y: hidden;
    }
    .top_title{
        font-size: 1.3rem;
    }
    .item_wrapper{
        font-size: 0.8rem;
    }
    .flex_title{
        font-size: 0.9rem;
        /* min-height: 40px; */
    }
    .helper{
        font-size: 0.7rem;
        min-height: 28px;
    }
    .input_flex{
        /* height: 160px; */
    }
    .edit_icon{
        font-size: 30px;
    }
    .edit_top{
        top: 205px;
        left: 10px;
        right: 10px;
        text-align: center;
        width: 98%;
        box-sizing: border-box;
    }
    .save_top{
        top: 205px;
        left: 10px;
        right: 10px;
        text-align: center;
        width: 98%;
    }
    .input_input{
        width: 150px;
    }
    .input_div{
        width: 100%;
    }
    .textarea_input_edit{
        width: 100%;
    }
    .textarea_input{
        width: 100%;
    }
    .textarea_input_wrong{
        width: 100%;
    }
    .details{
        height: calc(100vh - 208px);
    }
    .map_label{
        font-size: 0.9rem;
        justify-self: center;
    }
    .checkbox_text{
        font-size: 0.9rem;
    }
    .checkbox_text_disabled{
        font-size: 0.9rem;
    }
    .checkmark_disabled{
        width: 20px !important;
        height: 20px !important;
    }
    .checkmark{
        width: 20px !important;
        height: 20px !important;
    }
    .container{
        font-size: 1rem;
        height: 20px;
    }
    /* Style the checkmark/indicator */
.container .checkmark:after {
    left: 7px;
    top: 4px;
    width: 3px;
    height: 7px;
    }
    /* Style the checkmark/indicator */
.container .checkmark_disabled:after {
    left: 7px;
    top: 4px;
    width: 3px;
    height: 7px;
    }
}
@media (max-width: 850px){
    .item_wrapper{
        font-size: 0.6rem;
    }
}

@media (max-width: 768px){
    .map_label{
        font-size: 0.8rem;
    }
    .area_left_title_wrapper{
        font-size: 0.9rem;
    }
    .input_flex_edit{
        padding-left: 0px;
    }
    .item_title{
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 250px;
        white-space: nowrap;
    }
    .item_left{
        margin-left: 0px;
    }
    .item_title_div{
        width: 150px;
    }
    .item_title{
        font-size: 0.9rem;
    }
    .item_desc{
        font-size: 0.7rem;
    }
    .item_flex_indicator{
        height: 25px;
        padding: 0 5px;
        border-radius: 3px;
    }
    .item_type{
        font-size: 0.8rem;
    }
    .more_wrapper{
        border-radius: 3px;
        height: 25px;
    }
    .more{
        font-size: 1.2rem !important;
    }
    .item_flex_worker_text{
        font-size: 0.7rem;
    }
    .item_time{
        font-size: 0.6rem;
    }
    .flex{
        flex-direction: column;
        border-bottom: none;
        padding-bottom: 0px;
    }
    .top_right_flex{
        margin-left: 0px;
        margin-top: 50px;
    }
    .input_flex{
        padding-left: 0px;
    }
    .personal_subtitle{
        font-size: 1rem;
    }
    .edit_top{
        width: 100%;
        position: unset;
        margin-top: 10px;
        font-size: 0.9rem;
    }
    .edit{
        left: calc(50% - 120px);
    }
    .save{
        left: calc(50% - 120px);
    }

    .delete_account_divider{
        display: block;
        margin: 120px 0 0px 0;
    }
    .delete_account_divider_always{
        margin-top: 300px;
    }
    .checkbox_text{
        font-size: 0.8rem;
    }
    .checkbox_text_disabled{
        font-size: 0.8rem;
    }
    .flex_select_div{
        height: 300px;
        max-height: 300px;
        border: 1px solid #ffffff50;
        border-top: none;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 5px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow-y: scroll;
        margin-bottom: 300px;
    }
}


@media (max-width: 500px){
    .flex_select_div{
        margin-bottom: 0px;
    }
    .area_left_title_wrapper{
        margin-top: -15px;
        font-size: 0.7rem;
    }
    .map_label{
        font-size: 0.7rem;
    }
    .area_left_title_wrapper{
        font-size: 0.7rem;
    }
    .helper{
        min-height: 10px;
    }
    .verificar_top{
        padding: 2px 0;
    }
    .verificar_top_no_animation{
        padding: 2px 0;
    }
    .input_div_button_text_no_animation{
        font-size: 0.7rem;
    }
    .top_title{
        font-size: 1rem;
    }
    .personal_title{
        height: 50px;
    }
    .input_email{
        font-size: 0.8rem;
    }
    .input_input{
        font-size: 0.8rem;
    }
    .input_icon{
        width: 18px !important;
        height: 18px !important;
    }
    .status_div_title{
        font-size: 0.6rem;
    }
    .status_div_flex_title{
        font-size: 0.9rem;
    }
    .top_complete{
        font-size: 0.7rem;
    }
    .top_complete_arrow{
        width: 12px !important;
        height: 12px !important;
    }
    .top_complete_arrow_show{
        width: 12px !important;
        height: 12px !important;
    }
    .line_icon{
        width: 12px !important;
        height: 12px !important;
    }
    .line_text{
        font-size: 0.6rem;
    }
    .line_text_complete{
        font-size: 0.6rem;
    }
    .line_val_complete{
        width: 15px !important;
        height: 15px !important;
    }
    .input_title{
        font-size: 0.7rem;
        width: max-content;
    }
    .personal_subtitle{
        font-size: 0.9rem;
    }
    .edit_top_worker{
        font-size: 0.9rem;
        padding: 7px 12px;
    }
    .flex{
        height: max-content;
    }
    .top_edit_area{
        height: max-content;
    }
    .edit{
        font-size: 0.9rem;
        padding: 10px 0;
        width: 200px;
        left: calc(50% - 80px);
        bottom: 5px;
    }
    .save{
        font-size: 0.9rem;
        padding: 10px 0;
        width: 200px;
        left: calc(50% - 80px);
        bottom: 5px;
    }
    .save_top{
        font-size: 0.9rem;
        padding: 7px 12px;
    }
    .save_top_worker{
        font-size: 0.9rem;
        padding: 7px 12px;
    }
    .flex_title{
        font-size: 0.8rem;
    }
    .checkbox_text{
        font-size: 0.8rem;
    }
    .checkbox_text_disabled{
        font-size: 0.8rem;
    }
    .checkmark_image_disabled{
        width: 15px !important;
        height: 15px !important;
    }
    .checkmark_image{
        width: 15px !important;
        height: 15px !important;
    }
    .flex_bottom{
        flex-direction: column;
        justify-content: flex-start;
        height: 950px;
    }
    .details{
        height: calc(100vh - 200px);
        padding-bottom: 0px;
    }
    .worker_area{
        height: 850px;
    }
    .flex_left{
        width: 100%;
        margin-bottom: 20px;
    }
    .divider{
        height: 1px;
        margin: 5px 0;
    }
    .divider_active{
        height: 1px;
        margin: 5px 0;
    }
    .input_list{
        width: 100%;
        box-sizing: border-box;
    }
    .flex_left_mobile{
        margin-top: 100px;
    }
    .textarea_input{
        font-size: 0.8rem;
    }
    .textarea_input::placeholder{
        font-size: 0.8rem;
    }
    .image_wrapper{
        width: 100%;
        box-sizing: border-box;
    }
    .input[type=file]{
        width: 100%;
        height: 250px;
    }
    .textarea_input_edit{
        font-size: 0.8rem;
    }
    .textarea_input_edit::placeholder{
        font-size: 0.8rem;
    }
    .input_div_button_text{
        font-size: 0.7rem;
    }
    .delete_account_divider{
        display: block;
        margin: 120px 0 0px 0;
        display: block;
    }
    .delete_account_wrapper{
        margin-bottom: 140px;
    }


}