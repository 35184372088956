.home{
    width: 100%;
    /* border-top: 1px solid #ddd; */
    -webkit-box-shadow: inset 0px 6px 10px -4px rgba(0,0,0,0.59); 
    box-shadow: inset 0px 6px 10px -4px rgba(0,0,0,0.59);
    height: calc(100vh - 68px);
}
.welcome_2{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--brand);
    z-index: 3;
}
.home_back{
    display: flex;
    flex-direction: column;
    background-color: var(--brand);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}
.home_back_top_wrapper{
    background-image: url('../assets/home_3.jpg');
    background-position: left 0px top 0px;
    padding-bottom: 40px;
    position: relative;
}
.home_back_top{
    margin: 0 auto;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: background-color 300ms linear;
    transition: all 0.5s;
    width: 80%;
}
.home_divider{
    width: 100%;
    height: 2px;
    background: rgb(3,88,229);
    background: linear-gradient(140deg, rgba(3,88,229,1) 0%, rgba(255,120,90,1) 100%);
    display: block;
    /* transition: all 0.5s; */
    margin-bottom: 40px;
    border-radius: 10px;
}
.text_brand{
    margin-top: 50px;
    margin-bottom: 30px;
    width: 200px;
    z-index: 5;
    transition: all 500ms;
}
.text_brand_helper{
    color: #cccccc99;
    margin-top: 5px;
    text-align: center;
    width: 90%;
    font-size: 1rem;
    font-weight: 400;
}
.text_title{
    margin: 0px auto;
    margin-top: 100px;
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: uppercase;
    font-weight: 700;
    background-color: #474747;
    padding: 5px 7px;
    border-radius: 5px;
}
.text_title_underscore{
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
}
.main_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: 'center';
    width: 100%;
    background-color: var(--brand);
    padding: 10px;
    border-radius: 5px;
    /* border: 1px solid var(--neutral); */
    transition: 150ms all;
    box-sizing: border-box;
    background-color: #FF785A10;
}
.arrow_wrapper{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50px;
    left: 50px;
    opacity: 0;
    transition: 300ms opacity;
}
.arrow_wrapper_2{
    display: none;
    background-color: var(--brand);
    border-radius: 50%;
    border: 3px solid var(--action);
    padding: 0 5px;
}
.novo{
    position: absolute;
    right: 50px;
    top: 50px;
    background-color: var(--neutral);
    border-radius: 5px;
    padding: 10px;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
    z-index: 1;
    font-style: italic;
}
.novo:hover{
    opacity: 0.8;
    transition: 150ms all;
    cursor: pointer;
}
.arrow_wrapper_icon{
    color: var(--action);
    font-size: 2.7rem !important;
    margin-top: 10px;
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
.arrow_wrapper_text{
    color: var(--action);
    font-style: italic;
    font-size: 1rem;
}

@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
.main{
    margin: auto;
    display: flex;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    position: relative;
    border-top-right-radius: 0;
    transition: 200ms all;
}
.zero_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.zero{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    background-color: #0358e590;
    border: 2px solid #0358e5;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    height: 200px;
}
.zero:hover{
    opacity: 0.8;
}
.zero_title{
    font-size: 1.5rem;
    color: var(--neutral);
    font-weight: 600;
    text-transform: uppercase;
    align-self: center;
    justify-self: center;
}
.zero_info{
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
}
.zone{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.zone_img{
    background-color: var(--brand);
    border-radius: 50%;
    height: 70px !important;
    width: 70px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.5s;
    border: 2px solid #989898;
}
.zone_build_icon{
    color: #ffffff80;
    width: 40px !important;
    height: 40px !important;
}
.zone_person_icon{
    color: var(--neutral);
    width: 40px !important;
    height: 40px !important;
}
.zone_person_icon_small{
    color: #ffffff;
    font-size: 1.5rem !important;
    margin-bottom: -3px;
}
.zone_image_prof{
    width: 40px !important;
    height: 40px !important;
}
.zone_image_region{
    text-align: center;
    font-weight: 700;
    font-size: 0.7rem;
    color: white;
}
.zone_select{
    margin-top: 10px;
    width: 250px;
}
.zone_select_buttons{
    display: flex;
    width: 100%;
    height: 40px;
}
.zone_select_button_off{
    background-color: var(--job);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--neutral);
    border-width: 2px;
    border-style: solid;
    transition: 150ms all;
    animation-name: blue;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    z-index: 0;
}

.zone_select_button_off_profissional{
    background-color: var(--job);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--neutral);
    border-width: 2px;
    border-style: solid;
    transition: 150ms all;
    animation-name: orange;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    z-index: 0;
}


@keyframes blue {
    0% {
        background-color: #0358e5;
    }
    50% {
        background-color: #0358e540;
    }
    100% {
        background-color: #0358e5;
    }
}

@keyframes orange {
    0% {
        background-color: #FF785A;
    }
    50% {
        background-color: #FF785A40;
    }
    100% {
        background-color: #FF785A;
    }
}

.zone_select_button_off:hover{
    opacity: 0.7;
    transition: 150ms all;
}
.zone_select_button{
    background-color: var(--job);
    border-radius: 5px;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--neutral);
    transition: 150ms all;
    position: relative;
    overflow: hidden;
    --offset: 2px;
    z-index: 0;
}
/* .zone_select_button::before { 
    content: '';
    background: conic-gradient(transparent 270deg, white, transparent);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1;
    width: 100%;
    animation: rotate 2s linear infinite;
}

.zone_select_button::after {
    content: '';
    background: inherit;
    border-radius: inherit;
    position: absolute;
    inset: var(--offset);
    height: calc(100% - 2 * var(--offset));
    width: calc(100% - 2 * var(--offset));
}

.zone_select_button input {
    background: transparent;
    color: white;
    font-size: 1.5rem;
    position: absolute;
    inset: 0;
    z-index: 10;
    padding: 1.5rem;
} */

@keyframes rotate {
    from {
        transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
    }

    to {
        transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
    }
}
.zone_arrow_div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    transition: all 0.5s;
    width: 90px;
    min-width: 20px;
}
.zone_arrow_none{
    height: 5px;
    background-color: #252d36;
    width: 100%;
    transition: all 0.5s;
}
.zone_arrow_trabalhos_half{
    height: 5px;
    background-color: var(--job);
    width: 100%;
    background: linear-gradient(90deg, rgba(3,88,229,1) 0%, #252d36 73%, #252d36 100%);
    transition: all 0.5s;
}
.zone_arrow_trabalhos_half_reverse{
    height: 5px;
    background-color: var(--job);
    width: 100%;
    background: linear-gradient(270deg, rgba(3,88,229,1) 0%, #252d36 73%, #252d36 100%);
    transition: all 0.5s;
}
.zone_arrow_trabalhos{
    height: 5px;
    background-color: var(--job);
    width: 100%;
    transition: all 0.5s;
}
.zone_arrow_trabalhadores_half{
    height: 5px;
    background-color: var(--action);
    width: 100%;
    background: linear-gradient(90deg, rgba(255,120,90,1) 0%, #252d36 73%, #252d36 100%);
    transition: all 0.5s;
}
.zone_arrow_trabalhadores_half_reverse{
    height: 5px;
    background-color: var(--action);
    width: 100%;
    background: linear-gradient(270deg, rgba(255,120,90,1) 0%, #252d36 73%, #252d36 100%);
    transition: all 0.5s;
}
.zone_arrow_trabalhadores{
    height: 5px;
    background-color: var(--action);
    width: 100%;
    transition: all 0.5s;
}
.zone_arrow_final{
    height: 2px;
    border-top: 5px solid var(--action);
    width: 80px;
    margin-top: 5px;
}
.search_wrapper_disabled{
    margin: auto;
    margin-top: 15px;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: default;
    transition: all 0.5s;
    border: 1px solid var(--action);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    border: 2px solid var(--brand);
}
.search_wrapper{
    margin: auto;
    margin-top: 45px;
    background-color: var(--neutral);
    padding: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12em;
}
.search_wrapper:hover{
    opacity: 0.7;
    transition: 150ms;
}
.search_clear_wrapper_disabled{
    margin: auto;
    margin-top: 5px;
    background-color: var(--brand);
    transition: all 0.5s;
    border-radius: 3px;
    padding: 5px 5px;
    /* border: 1px solid #ffffff50; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    color: white;
    border: 1px solid var(--brand);
}
.search_clear_wrapper{
    margin: auto;
    margin-top: 5px;
    background-color: var(--brand);
    transition: all 0.5s;
    border-radius: 3px;
    padding: 5px 5px;
    border: 1px solid #ffffff50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    color: white;
}
.search_clear_wrapper:hover{
    opacity: 0.7;
    transition: all 100ms;
    cursor: pointer;
}
.search_clear_icon{
    color: var(--neutral);
}
.zone_search_icon{
    width: 25px !important;
    height: 25px !important;
    color: var(--neutral);
}
.ad{
    height: 50px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #ffffff20;
    margin-top: 200px;
    z-index: 15;
}
.ad_inner{
    height: 100%;
    width: 100%;
}
.ad_helper{
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    font-size: 0.6rem;
    font-style: italic;
    font-weight: 300;
}
.zone_search_button{
    color: #ffffff50;
    font-weight: 600;
    font-size: 0.7rem;
    text-transform: uppercase;
    text-align: center;
    min-width: max-content;
}
.home_geral{
    width: 80%;
    margin: auto;
    margin-top: 5px;
    height: 100%;
}
.home_back_bottom{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    max-height: 280px;
}

.home_explorar{
    text-align: center;
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--grey);
}
.home_back_publish{
    width: 80%;
    margin: 50px auto;
    /* background-color: var(--brand); */
    box-sizing: border-box;
}
.home_back_publish_special{
    width: 80%;
    margin: auto;
    /* background-color: var(--brand); */
}
.back_publish_title{
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--neutral);
}
.back_publish_title_special{
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--neutral);
    padding-bottom: 10px;
}
.back_publish_div_disabled{
    margin-top: 15px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff10;
}
.back_publish_div{
    margin-top: 10px;
    width: 100%;
    background-color: var(--job);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
}
.back_publish_div:hover{
    opacity: 0.8;
    transition: all 150ms;
}
.home_back_publish_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section_publicar{
    color: var(--neutral);
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
}
.back_publish_text{
    text-align: center;
    font-size: 0.9rem;
    color: var(--neutral);
}

.auth{
    margin: auto;
    margin-top: 5px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    color: var(--neutral);
    font-style: italic;
    font-size: 0.8rem;
}
.auth_specific{
    color: var(--job);
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: uppercase;
}
.auth_specific:hover{
    text-decoration: underline;
    text-underline-offset: 2px;
}
.back_publish_button{
    width: fit-content;
    background-color: var(--job);
    padding: 12px 30px;
    border-radius: 5px;
    color: var(--brand);
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    margin: auto;
    margin-top: 15px;
    color: white;
}
.back_publish_div_frontdrop{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: var(--grey);
    opacity: 0.6;
}
.back_publish_button_disabled{
    width: fit-content;
    background-color: var(--brand);
    padding: 9px 30px;
    border-radius: 5px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    position: relative;
    margin: auto;
}
.back_publish_button:hover{
    cursor: pointer;
    opacity: 0.7;
    transition: 150ms all ease-in-out;
}

.back_publish_button_action{
    margin-top: 20px;
    width: fit-content;
    background-color: var(--neutral);
    padding: 10px 30px;
    border-radius: 5px;
    color: var(--action);
    font-weight: 600;
    border: 3px solid transparent;
    cursor: pointer;
}
.back_publish_button_action:hover{
    background-color: var(--brand);
    color: var(--neutral);
    transition: 150ms;
}

.home_title{
    font-size: 1.5rem;
    font-weight: 500;
    padding: 15px 0 0 15px;
}
.section_spacer{
    height: 85%;
    width: 10px;
    background: rgb(22,31,40);
    background: linear-gradient(180deg, rgba(22,31,40,1) 0%, rgb(255, 255, 255) 100%);
    align-self: center;
    border-radius: 4px;
}
.section_one{
    display: flex;
    flex-direction: column;
    justify-content: center;    
    width: 46%;
}
.section_two{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 46%;
}
.section_content{
    height: fit-content;
    align-self: center;
    justify-content: center;
    width: 100%;
    background-color: #ffffff10;
    padding: 10px 0;
    border-radius: 5px;
    max-height: 450px;
}
.section_button{
    padding: 10px;
    width: fit-content;
    margin: auto;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #ffffff;
    cursor: pointer;
    border: 3px solid transparent;
    margin-bottom: 10px;
    /* border: 2px solid var(--job); */
}
.section_button:hover{
    opacity: 0.7;
    transition: 150ms;
}

.section_title{
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    color: var(--neutral);
}
.section_button_right{
    padding: 10px;
    width: fit-content;
    margin: auto;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #ffffff;
    cursor: pointer;
    border: 3px solid transparent;
    margin-bottom: 10px;
    /* border: 2px solid var(--action); */
}
.section_button_right:hover{
    opacity: 0.7;
    transition: 150ms;
}
.section_title_right{
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--neutral);
}
.section_image_text_title{
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--neutral);
    margin-top: 5px;
    text-align: center;
}
.section_image_text{
    display: flex;
    justify-content: center;
    padding: 10px 0;
    text-align: center;
    font-size: 0.9rem;
    color: var(--neutral);
}
.section_image_wrapper{
    display: flex;
    justify-content: center;
}
.section_img{
    display: block;
    transition: 0.3s ease-in-out;
    color: var(--job);
    width: 80px !important;
    height: 80px !important;
    padding: 0 0 10px 0;
}
.section_img_mini{
    display: block;
    transition: 0.3s ease-in-out;
    color: var(--neutral);
    width: 40px !important;
    height: 40px !important;
    padding: 0 0 5px 0;
}

.back_bottom_trabalhador{
    width: 100%;
    background-color: #FF785A;
    margin-top: 90px;
}
.publish{
    display: flex;
    position: absolute;
    left: calc(50% - 175px);
    bottom: 15%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.publish_or{
    color: white;
    font-weight: 600;
    text-decoration: underline;
}
.publish_main{
    display: flex;
    width: 350px;
    background-color: var(--brand);
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 5px;
    border: 4px solid white;
    cursor: pointer;
    flex-direction: column;
    position: relative;
}
.publish_main:hover{
    background-color: white;
    transition: 150ms;
}
.publish_main:hover > *{
    color: var(--brand);
}
.publish_text{
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
}
.publish_icon{
    color: white;
    position: absolute;
    right: 3px;
    top: 3px;
    height: 25px !important;
    width: 25px !important;
}

.worker_button{
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: var(--action);
    font-weight: 600;
    background-color: white;
    border-radius: 5px;
    padding: 6px;
    z-index: 3;
    font-size: 0.8rem;
}
.worker_button:hover{
    text-decoration: underline;
    cursor: pointer;
}
.skeleton_content_in_img{
    width: 50%;
    margin: auto;
    height: 150px;
    border-radius: 5px;
    display: block;
    background-color: var(--skeleton);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.skeleton_content_in{
    text-align: center;
    margin: 10px auto;
    width: 50%;
    height: 50px;
    border-radius: 5px;
    background-color: var(--skeleton);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.skeleton_worker_botbanner{
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 3;
    width: 150px;
    height: 30px;
    border-radius: 5px;
    background-color: var(--skeleton);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.skeleton_publish{
    position: absolute;
    left: calc(50% - 150px);
    bottom: 15%;
    width: 300px;
    height: 90px;
    border-radius: 5px;
    background-color: var(--skeleton);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.skeleton_tag{
    position: absolute;
    top: 40px;
    left: 40px;
    width: 300px;
    height: 100px;
    border-radius: 5px;
    background-color: var(--skeleton);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.footer{
    width: 100%;
    border-top: 1px solid var(--grey);
    padding-bottom: 20px;
    margin-top: 60px;
}
.footer_div{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    margin-top: 50px;
    padding-bottom: 10px;
}
.footer_div_column{
    width: 200px;
    margin-right: 50px;
}
.footer_div_text{
    font-weight: 700;
    cursor: pointer;
    color: var(--neutral);
    margin-top: 5px;
    width: max-content;

}
.footer_div_text:hover{
    text-decoration: underline;
}
.footer_icon_div{
    display: flex;
    margin-top: 5px;
}
.footer_icon{
    cursor: pointer;
    color: var(--neutral);
}

.notification_area{
    min-height: 80px;
    max-height: 300px;
    width: 100%;
    margin: auto;
    border: 2px solid white;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px;
    box-sizing: border-box;
    overflow-y: auto;
}
.notification_empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
}
.notification_empty_text{
    color: var(--neutral);
    font-style: italic;
    /* margin-top: 22px; */
}
.backdrop{
    position: absolute;
    width: 100%;
    height: calc(100vh - 68px);
    background-color: #000000dd;
    z-index: 1;
    transition: all 500ms ease-in-out;
    overflow: hidden;
}
.notification_user{
    animation-name: blue_two;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-bottom: 2px solid var(--yellow);
}
.notification_user:hover{
    opacity: 0.8;
    transition: 150ms all;
}
.notification_worker{
    animation-name: action;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-bottom: 2px solid var(--yellow);
}
.notification_user:hover{
    opacity: 0.8;
    transition: 150ms all;
}
.notification_red{
    animation-name: red !important;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.notification{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px;
    box-sizing: border-box;
    height: 80px;
    max-height: 80px;
    border-radius: 5px;
    animation-name: yellow;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    /* border: 2px solid transparent; */
}
.notification:hover{
    /* border: 2px solid var(--yellow); */
    cursor: pointer;
}
.notification:last-child{
    margin-bottom: 0px;
}
.notification_left{
    width: 20%;
    display: flex;
    justify-content: center;
}
.notification_left_icon{
    width: 40px !important;
    height: 40px !important;
}
.notification_right{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.notification_right_column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
}
.notification_right_flex{
    display: flex;
    align-items: center;
}
.notification_right_image{
    width: 25px !important;
    height: 25px !important;
    border-radius: 50%;
    color: #0358e5;
}
.notification_right_name{
    margin-left: 5px;
    font-weight: 600;
    font-size: 1.1rem;
}
.notification_short_text_helper{
    font-weight: 500;
    text-align: left;
    margin-bottom: 5px;
    font-size: 0.9rem;
}
.notification_short_text{
    font-weight: 600;
    text-align: left;
    font-size: 0.9rem;
}
.notification_right_text{
    width: 300px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.9rem;
    white-space: pre-line;
    text-align: left;
    align-self: flex-start;
    max-height: 40px;
}
.notification_right_reservation{
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    max-width: 150px;
}
.notification_right_time{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.notification_right_date{
    font-size: 0.8rem;
    font-weight: 500;
}
.notification_right_hour{
    font-size: 0.8rem;
    font-weight: 500;
}
/* .upper_divider{
    margin-top: 120px;
    width: 100%;
    height: 2px;
    background-color: white;
    display: inline-block;
} */
.home_back_publish_special_wrapper{
    width: 100%;
}
.upper_wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    /* border-top: 2px solid var(--brand); */
    animation-name: welcome;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    flex-direction: column;
    align-items: center;
}
.upper_wrapper_close{
    background-color: var(--neutral);
    padding: 5px 20px;
    border-radius: 3px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 0.8rem;
    text-align: center;
}
.upper_wrapper_close:hover{
    opacity: 0.8;
    transition: 150ms all;
}
.upper_wrapper_text{
    text-align: center;
    width: 80%;
    margin: auto;
}
.upper_wrapper_text_title{
    margin-top: 20px;
    color: var(--neutral);
    font-weight: 600;
    font-size: 1.5em;
}
.upper_wrapper_text_subtitle{
    color: var(--neutral);
    margin-top: 5px;
    font-size: 1.1rem;
}
.upper_wrapper_text_subtitle_2{
    color: var(--neutral);
    margin-top: 10px;
}
.banner{
}
.banner_text{
    background-color: var(--yellow);
    color: var(--brand);
    width: fit-content;
    padding: 5px;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
    border-radius: 2px;
    margin-bottom: 3px;
}
@keyframes welcome {
    0% {
      background-color: #ffffff20;
    }
    50% {
        background-color: #ffffff10;
    }
    100% {
        background-color: #ffffff20;
    }
}
.upper{
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: center;
    margin-top: 30px;
    height: 45px;
}
.upper_two{
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: center;
    margin-top: 10px;
}
.upper_side_wrapper{
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.upper_wrapper_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home_arrow{
    width: 180px;
    margin-top: 10px;
    margin-bottom: -10px;
}
.upper_side{
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
.upper_side_text_helper{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--job);
    width: fit-content;
    border-radius: 5px;
}
.upper_side_text{
    color: var(--neutral);
    text-align: center;
    font-size: 0.95rem;
    text-transform: uppercase;
    font-weight: 600;
    max-width: 500px;
    text-align: center;
    margin: auto;
    padding: 5px;
}
.section_img_mini_mini{
    width: 30px !important;
    height: 30px !important;
    color: var(--neutral);
    margin-bottom: 5px;
}
.upper_button{
    display:flex;
    background-color: var(--job);
    width: 100%;
    border-radius: 5px;
    border: 2px solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
}
.upper_button:hover{
    opacity: 0.6;
    transition: 150ms all;
    cursor: pointer;
}
.section_publicar_mini{
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
}
.home_back_publish_short{
    margin-top: 100px;
}
.has_messages{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    background-color: var(--yellow);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
}
.has_messages:hover{
    opacity: 0.8;
    transition: 150ms all;
}
@keyframes yellow {
    0% {
      background-color: #ffffff;
    }
    50% {
        background-color: #ffeeab;
    }
    100% {
        background-color: #ffffff;
    }
}

@keyframes red {
    0% {
      background-color: #ff7c75;
    }
    50% {
        background-color: rgb(255, 199, 199);
    }
    100% {
        background-color: #ff7c75;
    }
}

@keyframes blue_two {
    0% {
      background-color: #ffffff;
    }
    50% {
        background-color: #90baff;
    }
    100% {
        background-color: #ffffff;
    }
}

@keyframes action {
    0% {
      background-color: #ffffff;
    }
    50% {
        background-color: #ff9a83;
    }
    100% {
        background-color: #ffffff;
    }
}

@keyframes color {
    0% {
      background-color: var(--skeleton);
    }
    50% {
      background-color: #ccc;
    }
    100% {
      background-color: var(--skeleton);
    }
}

.ou_wrapper{
    display: flex;
    justify-content: center;
    margin: 70px auto 45px auto;
    background-color: var(--brand);
    width: fit-content;
    position: relative;
    width: 80%;
}
.ou_line{
    width: 100%;
    height: 1px;
    background-color: #ffffff40;
    display: inline-block;
}
.ou{
    background-color: var(--brand);
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--neutral);
    position: absolute;
    top: -8px;
    padding: 0 10px;
}

.new{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}
.new_side{
    flex: 1;
}
.new_title{
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: uppercase;
}

@media (max-width: 1440px){
    .section_content{
        max-height: 270px;
    }
    .zone_select_buttons{
        height: 30px;
    }
    .text_brand{
        width: 200px;
    }
    .search_wrapper{
        padding: 5px 0;
        /* font-size: 1.5em; */
    }
    .zone_search_icon{
        display: none !important;
    }
    .zone_search_button{
        font-size: 0.7em;
    }
    .zone_arrow_div{
        margin-top: -35px;
    }
    .zone_arrow_final{
        display: none;
    }
    .zone_img{
        height: 50px !important;
        width: 50px !important;
    }
    .zone_person_icon{
        width: 25px !important;
        height: 25px !important;
    }
    .zone_image_prof{
        width: 25px !important;
        height: 25px !important;
    }
    .zone_build_icon{
        width: 25px !important;
        height: 25px !important;
    }
    .zone_person_icon_small{
        font-size: 1.3rem !important;
    }
    .back_publish_title{
        font-size: 0.9rem;
    }
    .back_publish_div{
        padding: 0.5em;
    }
    .section_publicar{
        font-size: 0.9em;
    }
    .section_img_mini{
        width: 35px !important;
        height: 35px !important;
    }
    .home_explorar{
        margin-top: 10px;
    }
    .section_img{
        width: 50px !important;
        height: 50px !important;
    }
    .section_button{
        padding: 5px;
    }
    .section_button_right{
        padding: 5px;
    }
    
}
@media (max-width: 1024px){
    .section_img_mini_mini{
        width: 25px !important;
        height: 25px !important;
    }
    .new_title{
        font-size: 1rem;
    }
    .upper{
        height: 60px;
    }

    .zone_image_region{
        font-size: 0.9rem;
    }
    .zone_search{
        right: 238px;
        bottom: -330px;
    }
    .main{
        margin-top: 0px;
        transition: 200ms all;
        min-width: 700px;
    }
    .home_back_top{
        width: 100%;
    }
    .back_publish_title_special{
        margin: auto;
        text-align: center;
        align-self: center;
    }
    .home_back_publish_special{
        width: 100%;
        text-align: center;
    }
    .upper_side_text{
        max-width: 280px;
        font-size: 0.9rem;
    }
    .upper_wrapper_text_title{
        font-size: 1.2rem;
    }
    .upper_wrapper_text_subtitle{
        font-size: 1rem;
    }
    .section_publicar_mini{
        font-size: 0.9rem;
    }
    .text_brand_helper{
        font-size: 0.9rem;
    }
}

@media (max-width: 768px){
    .zero_wrapper{
        flex-direction: column;
    }
    .zero{
        height: 140px;
    }
    .novo{
        display: none;
    }
    .arrow_wrapper{
        left: 50px;
    }
    .arrow_wrapper_text{
        display: none;
    }
    .arrow_wrapper_icon{
        font-size: 3rem !important;
    }
    .home_arrow{
        width: 140px;
    }
    .upper{
        height: 75px;
    }
    .home_back_publish_short{
        margin-top: 100px !important;
    }
    .notification_area{
        margin-top: 20px;
        width: 90%;
    }
    .home_back_publish{
        width: 100%;
        text-align: center;
    }
    .home_back_top{
        width: fit-content;
    }
    .back_publish_div{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .back_publish_title{
        margin: auto;
        text-align: center;
        align-self: center;
    }
    .home_geral{
        width: 100%;
        align-items: center;
    }
    .home_back_bottom{
        flex-direction: column;
        max-height: none;
        margin-top: 15px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .section_one{
        width: 99.5%;
        margin: auto;
        margin-top: 15px;
    }
    .section_two{
        width: 99.5%;
        margin: auto;
    }
    .section_spacer{
        width: 100%;
        border-radius: 0;
        background: var(--brand);
    }
    .text_title{
        margin-top: 200px;
    }
    .main{
        flex-direction: column;
        height: 300px;
        min-width: 300px;
        width: 390px;
    }
    .zone_img{
        height: 50px !important;
        width: 50px !important;
        margin-right: 10px;
    }
    .zone_build_icon{
        width: 30px !important;
        height: 30px !important;
    }
    .zone_person_icon{
        width: 30px !important;
        height: 30px !important;
    }
    .zone_image_prof{
        width: 30px !important;
        height: 30px !important;
    }
    .zone_image_region{
        font-weight: 700;
        font-size: 0.6rem;
    }
    .zone{
        flex-direction: row;
        width: 100%;
    }
    .zone_arrow_div{
        transform: rotate(90deg);
        width: 40px;
        margin-left: 45px;
        margin-top: 4px;
    }
    .zone_search{
        left: 0px;
        bottom: -150px;
        width: 220px;
        text-align: center;
    }
    .section_publicar_mini{
        font-size: 0.9rem;
        max-width: 150px;
    }
    .upper_side_text{
        max-width: 260px;
        font-size: 0.9rem;
    }
    /* .upper_side_wrapper{
        margin-left: 10px;
        margin-right: 10px;
    } */
     .notification_right_text{
        width: 250px;
    }
    
}

@media (max-width: 500px){
    .arrow_wrapper{
        display: none;
    }
    .arrow_wrapper_2{
        display: block;
        left: calc(50% - 1.5rem - 1.5px - 5px);
        bottom: 5px;
        z-index: 10;
        position: fixed;
    }
    .home_arrow{
        width: 100px;
    }
    .notification_short_text_helper{
        font-size: 0.8rem;
    }
    .notification_short_text{
        font-size: 0.8rem;
    }
    .banner_text{
        font-size: 0.7rem;
    }
    .upper_wrapper_close{
        font-size: 0.8rem;
    }
    
    .upper_wrapper_text_subtitle{
        font-size: 0.8rem;
    }
    .upper_side_text{
        font-size: 0.7rem;
        max-width: 140px;
    }
    .section_publicar_mini{
        font-size: 0.8rem;
        max-width: 120px;
    }
    .text_brand_helper{
        font-size: 0.8rem;
    }
    .notification_area{
        min-height: 60px;
    }
    .notification_left_icon{
        width: 25px !important;
        height: 25px !important;
    }
    .notification_right_image{
        width: 20px !important;
        height: 20px !important;
    }
    .notification_right_name{
        font-size: 0.9rem;
    }
    .notification_right_reservation{
        font-size: 0.7rem;
    }
    .notification_right_date{
        font-size: 0.7rem;
    }
    .notification_right_hour{
        font-size: 0.7rem;
    }
    .notification{
        max-height: 60px;
    }
    .notification_right_column{
        height: 55px;
    }

    .home{
        height: calc(100vh - 38px);
    }
    .footer_div{
        flex-direction: column;
    }
    .footer_div_column{
        margin-top: 20px;
    }
    .zone_select{
        width: 100%;
    }
    .main{
        width: 90%;
        margin: auto;
        padding-top: 0px;
        height: unset;
    }
    .zone_arrow_div{
        margin-left: 5px;
    }
    .home_back_bottom{
        margin-top: 10px;
    }
    .text_brand{
        width: 200px;
    }
    .zone_img{
        display: none;
    }
    .zone_arrow_div{
        display: none;
    }
    .zone{
        margin: 15px 0;
    }
    .text_title{
        font-size: 1rem;
        margin-top: 120px;
        margin-bottom: 10px;
        font-size: 0.9rem;
    }
    .home_explorar{
        font-size: 1rem;
    }
    .back_publish_title{
        font-size: 1rem;
    }
    .back_publish_title_special{
        font-size: 1rem;
    }
    .section_img_mini{
        width: 40px !important;
    }
    .section_publicar{
        font-size: 0.9rem;
    }
    .auth_specific{
        font-size: 0.7rem;
    }
    .section_img{
        width: 50px !important;
    }
    .section_image_text{
        font-size: 0.8rem;
    }
    .section_button{
        padding: 8px;
    }
    .section_title{
        font-size: 0.8rem !important;
    }
    .section_title_right{
        font-size: 0.8rem !important;
    }
    .footer_div_text{
        font-size: 0.8rem;
    }
    .footer_div{
        margin-top: 30px;
    }
    .notification_right_text{
        width: 150px;
    }
}

@media (max-width: 350px){
    .main{
        width: 80%;
    }
}