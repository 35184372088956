@import '../index.css';

.sessao{
    position: fixed;
    right: calc(50% - (150px)); 
    top: 50px;
    padding: 10px;
    width: 300px;
    text-align: center;
    background-color: #161F28ee;
    color: var(--neutral);
    border-radius: 20px;
    border: 4px solid white;
    cursor: default;
    z-index: 20;
    font-weight: 600;
    box-sizing: border-box;
    font-size: 1.2rem;
    -webkit-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.7);
    -moz-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.7);
    box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.7);
}
.sessao_sub{
    position: fixed;
    right: calc(50% - (275px)); 
    top: 130px;
    padding: 10px;
    width: 300px;
    text-align: center;
    background-color: #161F28ee;
    color: var(--neutral);
    border-radius: 20px;
    border: 4px solid white;
    cursor: default;
    z-index: 20;
    font-weight: 600;
    box-sizing: border-box;
    font-size: 1.2rem;
    -webkit-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.7);
    -moz-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.7);
    box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.7);
}


@media (max-width: 1024px) {
    .sessao_sub{
        right: calc(50% - (250px)); 
    }
}


@media (max-width: 768px) {
    .sessao_sub{
        width: 200px;
        right: calc(50% - (125px)); 
        font-size: 1rem;
    }
    .sessao{
        width: 200px;
        right: calc(50% - (100px)); 
        font-size: 1rem;
    }
}


@media (max-width: 500px){
    .sessao_sub{
        right: calc(50% - 100px);
        width: 150px;
        font-size: 0.9rem;
        z-index: 6;
        top: 50px;
    }
    .sessao{
        font-size: 0.9rem;
        z-index: 6;
    }
}