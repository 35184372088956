@import url('https://fonts.googleapis.com/css2?family=Unna:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@300;400;500&display=swap');

@import "~react-image-gallery/styles/css/image-gallery.css";

html{
  font-size: 90%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
:root {
  --neutral: #FFFFFF;
  --brand: #161F28;
  --action: #FF785A;
  --helper: #BA8376;
  --grey: #71848d;
  --green: #398606;
  --check: #0358e5;
  --yellow: #fdd835;
  --red: #ff3b30;
  --background: #f2f4f5;
  --skeleton: #f3f3f330;
  --job: #0358e5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.interesse{
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  border-radius: 5px;
  width: 50%;
  cursor: pointer;
}
.interesse_title{
  font-size: 0.8rem;
}
.interesse_val{
  text-align: center;
  font-weight: 600;
  margin: 5px 0;
}
.interesse_link{
  text-align: center;
  margin: 5px 0;
  color: var(--action);
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid var(--job);
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
  object-fit: cover;
}
.image-gallery-right-nav{
  right: 30px;
}
.image-gallery-icon:focus{
  outline: 2px solid var(--job);
}
.image-gallery-icon:hover {
  color: var(--job);
}
.image-gallery-fullscreen-button{
  right: 50px;
}
html { 
  height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  height: 100%;
  background-color: var(--brand);
}
p{
  margin: 0;
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #2c2b2c #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px !important;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
  background-color: #2c2b2c;
}

*::-webkit-scrollbar-thumb {
  background-color: #2c2b2c;
  border-radius: 20px !important;
  border: 1px solid #ffffff;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
  color: black !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.796) !important;
  font-size: 1rem !important;
  font-family: 'Roboto', sans-serif !important;
}

.css-1r5wa48-MuiList-root{
  padding: 0 !important;
}

.css-ishquk-MuiButtonBase-root-MuiListItemButton-root:hover{
  background-color: #65b5f633 !important;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: #65b5f633 !important;
}

.publicar-enter{
  opacity: 0;
}
.publicar-enter-active {
  opacity: 1;
  transform: translateY(30%);
  transition: 600ms;
}
.publicar-enter-done{
  transform: translateY(30%);
}
.publicar-exit {
  opacity: 1;
  transform: translateY(30%);
}
.publicar-exit-active {
  opacity: 0;
  transform: translateY(-30%);
  transition: 200ms;
}

.welcome-enter{
  opacity: 0;
  transform: translateY(-40%);
}
.welcome-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: 600ms;
}
.welcome-enter-done{
  /* transform: translateY(40%); */
}
/* .welcome-exit {
  opacity: 1;
  transform: translateY(40%);
} */
.welcome-exit-active {
  opacity: 0;
  transform: translateY(-40%);
  transition: 200ms;
}

.transition-enter{
  opacity: 0;
}
.transition-enter-active {
  opacity: 1;
  transform: translateY(40%);
  transition: 600ms;
}
.transition-enter-done{
  transform: translateY(40%);
}
.transition-exit {
  opacity: 1;
  transform: translateY(40%);
}
.transition-exit-active {
  opacity: 0;
  transform: translateY(-40%);
  transition: 200ms;
}

.reservation-enter{
  opacity: 0;
}
.reservation-enter-active {
  opacity: 1;
  transform: translateY(30%);
  transition: 600ms;
}
.reservation-enter-done{
  transform: translateY(30%);
}
.reservation-exit {
  opacity: 1;
  transform: translateY(30%);
}
.reservation-exit-active {
  opacity: 0;
  transform: translateY(-30%);
  transition: 200ms;
}

.reservation_less-enter{
  opacity: 0;
}
.reservation_less-enter-active {
  opacity: 1;
  transform: translateY(20%);
  transition: 600ms;
}
.reservation_less-enter-done{
  transform: translateY(20%);
}
.reservation_less-exit {
  opacity: 1;
  transform: translateY(20%);
}
.reservation_less-exit-active {
  opacity: 0;
  transform: translateY(-20%);
  transition: 200ms;
}



.banner-enter{
  opacity: 0;
}
.banner-enter-active {
  opacity: 1;
  transform: translateY(10%);
  transition: 600ms;
}
.banner-enter-done{
  transform: translateY(10%);
}
.banner-exit {
  opacity: 1;
  transform: translateY(10%);
}
.banner-exit-active {
  opacity: 0;
  transform: translateY(-10%);
  transition: 200ms;
}




.fade{
  z-index: 10;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: 200ms;
}