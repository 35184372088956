.worker{
    height: calc(100vh - 68px);
    position: relative;
    overflow-y: auto;
    background-color: var(--action);
    border-top: 1px solid #fff;
    scroll-behavior: smooth;
}
.reservar_upper_title{
    font-size: 3rem;
    font-weight: 800;
    color: white;
    margin: auto;
    width: fit-content;
    margin-top: 20px;
}
.middle_images{
    width: 100%;
    margin-left: 20px;
    margin-top: -5px;
}
.middle_images_background{
    display: flex;
    width: fit-content;
    /* background-color: var(--brand); */
    padding: 0 5px;
    border-radius: 40px;
    border-top-left-radius: 0;
    align-items: center;
}
.selected_worker{
    width: 34px;
    height: 3px;
    background: var(--action);
    position: absolute;
    bottom: -8px;
    left: -2px;
}
.top_image_div{
    background-color: white;
    width: 30px;
    height: 30px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}
.top_image_div_selected{
    background-color: var(--action);
    width: 30px;
    height: 30px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.top_image_selected{
    width: 36px;
    height: 36px;
    position: absolute;
    left: calc(50% - 18px);
    top: calc(50% - 18px);
}
.top_image{
    width: 20px;
    height: 20px;
}
.normal_back{
    width: 80%;
    margin: auto;
    display: flex;
    margin-top: 20px;
    color: white;
}
.normal_back_left{
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    color: white;
}
.normal_back_left:hover{
    border-bottom: 1px solid white;
}
.normal_back_right{
    display: flex;
    margin-left: 15px;
    font-size: 0.7rem;
    align-items: center;
    border-radius: 30px;
    padding: 5px 10px;
    background-color: white;
    position: relative;
}
.normal_back_right_dir{
    margin: 0 5px;
    cursor: pointer;
    color: var(--action);
}
.normal_back_right_dir:hover{
    opacity: 0.8;
    text-decoration: underline;
}
.normal_back_right_sep_wrapper{
    margin: 0 6px;
}
.normal_back_right_sep{
    position: absolute;
    height: calc(100% + 8px);
    top: -4px;
    width: 2px;
    color: var(--action);
    background-color: var(--action);
    transform: rotateZ(35deg);
    border-radius: 20px;
}
.main{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    overflow-y: auto;
}
.main_top{
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 5px;
    padding: 16px;
    margin-top: 30px;
    background-color: white;
    border: 4px solid transparent;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}
.main_top_left{
    display: flex;
    width: 30%;
}
.left_div_wrapper{
    display: flex;
}
.left_img{
    height: 120px !important;
    width: 120px !important;
    border-radius: 5px;
    object-fit: cover;
}
.left_div{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    min-width: 260px;
}
.left_div_inner{
    display: flex;
}
.left_name_wrapper{
}
.left_name{
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    width: max-content;
}
.left_type{
    color: #ccc;
    font-weight: 500;
    margin-top: 5px;
    font-size: 0.8rem;
}
.left_type_company{
    color: #ccc;
    font-weight: 400;
    font-size: 0.9rem;
}
.description_wrapper{
    display: flex;
    flex-direction: column;
    min-height: 80px;
    text-align: left;
    width: 100%;
    min-width: 600px;
    margin-top: 35px;
}
.description_title{
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    font-size: 1rem;
    margin-top: 10px;
    width: max-content;
}
.description{
    color: var(--brand);
    font-size: 0.9rem;
    white-space: pre-line;
    width: 100%;
}
.top_message{
    position: absolute;
    top: 16px;
    right: 16px;
    border: 3px solid transparent;
    box-sizing: border-box;
    font-weight: 600;
    background-color: var(--brand);
    color: white;
    border-radius: 5px;
    font-size: 0.8rem;
    cursor: pointer;
    text-transform: uppercase;
    padding: 10px;
    transition: all 150ms ease-in-out;
}
.top_message_text{
}
.top_message_icon{
    display: none !important;
}
.top_message:hover{
    border: 3px solid var(--brand);
    background-color: white;
    color: var(--brand);
    transition: 150ms;
}
.main_bottom{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;  
}
.bottom_left{
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    border-radius: 5px;
    padding: 16px;
    background-color: white;
    border: 4px solid transparent;
    box-sizing: border-box;
    height: fit-content;
}

.bottom_title{
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    font-size: 1rem;
}
.bottom_contact_wrapper{
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.bottom_icon{
    font-size: 1rem !important;
    background-color: var(--brand);
    padding: 5px;
    border-radius: 3px;
    align-self: center;
    color: white !important;
}
.bottom_contact{
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--brand);
    margin-left: 10px;
    align-self: center;
}

.bottom_right{
    display: flex;
    width: 50%;
    position: relative;
    border-radius: 5px;
    background-color: white;
    box-sizing: border-box;
    margin-left: 10px;
    justify-content: space-around;
    height: fit-content;
}
.bottom_right_wrapper{
    text-align: center;
    padding-top: 20px;
}
.bottom_right_title{
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    font-size: 1rem;
    width: 150px;
}
.list{
    padding: 10px 0;
}
.list_el_wrapper{
    margin: 10px 0;
}
.list_el{
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
}
.list_el_active{
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: var(--action);
    text-underline-offset: 3px;
}
.bottom_right_divider{
    width: 2px;
    left: calc(50% - 1px);
    height: 100%;
    background-color: var(--action);
    position: absolute;
}
.message{
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 40px;
    width: 100%;
    box-sizing: border-box;
}
.message_top_flex{
    display: flex;
    justify-content: space-between;
}
.user_info_number{
    font-size: 1rem;
    font-weight: 700;
    color: var(--brand);
    margin-left: 10px;
    text-transform: uppercase;
}
.message_left{
    display: flex;
}
.message_left_user_name{
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
}
.message_img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}
.message_textarea{
    width: calc(100% - 20px);
    height: 100px;
    border-radius: 5px;
    resize: none;
    border: none;
    background-color: var(--background);
    margin-top: 10px;
    padding: 10px 10px;
    font-family: inherit;
    font-size: 1.1rem;
    border-bottom: 2px solid transparent;
    color: var(--brand);
}
.message_textarea:focus{
    outline: none !important;
    border-bottom: 2px solid var(--brand);
}
.message_textarea:hover{
    outline: none !important;
}
.message_enviar_div{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.message_enviar{
    padding: 10px 20px;
    background-color: var(--brand);
    border-radius: 5px;
    font-weight: 600;
    color: white;
    border: 3px solid var(--brand);
    transition: background-color 150ms ease-in-out;
    text-transform: uppercase;
    font-size: 0.9rem;
}
.message_enviar:hover{
    background-color: white;
    color: var(--brand);
    border: 3px solid var(--brand);
    cursor: pointer;
}
.message_enviar_disabled{
    padding: 10px 20px;
    background-color: var(--background);
    border-radius: 5px;
    font-weight: 600;
    border: 3px solid var(--background);
    color: #ccc;
    text-transform: uppercase;
    font-size: 0.9rem;
    transition: all 150ms ease-in-out;
}

.textarea_wrapper{
    position: relative;
}
.message_textarea_disabled{
    width: calc(100% - 20px);
    height: 100px;
    border-radius: 5px;
    resize: none;
    border: none;
    background-color: #161F2880;
    margin-top: 10px;
    padding: 10px 10px;
    font-family: inherit;
    font-size: 1rem;
    border-bottom: 2px solid transparent;
    color: var(--brand);
    filter: blur(2px);
}
.frontdrop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.frontdrop_text{
    margin: 0 auto;
    color: white;
    font-weight: 500;
    font-size: 0.9rem;
}
.frontdrop_text_action{
    margin: 10px auto;
    color: var(--brand);
    font-weight: 600;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 0.9rem;
}
.frontdrop_text_action:hover{
    cursor: pointer;
    color: var(--neutral);
    background-color: var(--brand);
    transition: all 150ms ease-in-out;
}

.skeleton{
    background-color: var(--skeleton);
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--brand);
    margin: 20px auto;
}

@keyframes color {
    0% {
      background-color: var(--skeleton);
    }
    50% {
      background-color: #ccc;
    }
    100% {
      background-color: var(--skeleton);
    }
}

@media (max-width: 1300px){
    .description_wrapper{
        min-width: 400px;
    }
}
@media (max-width: 1024px){
    .main_bottom{
        flex-direction: column;
    }
    .bottom_left{
        width: 100%;
    }
    .bottom_right{
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
    }
    .left_div_wrapper{
        flex-direction: column;
    }
    .description_wrapper{
        margin-left: 20px;
        margin-top: 0;
        min-width: 450px;
    }
    .top_message_text{
        display: none;
    }
    .top_message_icon{
        display: block !important;
    }
}
@media (max-width: 768px){
    .top_message{
        padding: 5px;
    }
    .description_wrapper{
        min-width: 10px;
        max-width: 250px;
    }
}

@media (max-width: 768px){
    .description_wrapper{
        width: 100%;
    }
    .description{
        font-size: 0.7rem;
        width: max-content;
        max-width: 250px;
    }
    .description_title{
        font-size: 0.7rem;
    }
    .left_div_inner{
        flex-direction: column;
    }
    .left_div{
        min-width: 10px;
    }
    .description_title{
        width: max-content;
        font-size: 0.7rem;
    }
    .left_name{
        font-size: 0.9rem;
    }
    .middle_images{
        margin-left: 0;
        margin-top: 0;
        max-width: 200px;
        border-radius: 0;
        overflow-x: auto;
        padding-left: 5px;
    }
    .left_img{
        width: 90px !important;
        height: 90px !important;
    }
}

@media (max-width: 500px){
    .worker{
        height: calc(100vh - 38px);
    }
    .reservar_upper_title{
        font-size: 2rem;
    }
    .normal_back_left_symbol{
        font-size: 0.9rem !important;
    }
    .normal_back_left_text{
        font-size: 0.8rem;
    }
    .main_top{
        margin-top: 20px;
    }
    .normal_back_right{
        padding: 3px 8px;
    }
    .normal_back_right_dir{
        font-size: 0.6rem;
    }
    .left_img{
        width: 70px !important;
        height: 70px !important;
    }
    .left_name{
        font-size: 0.7rem;
    }
    .top_image_div{
        width: 10px !important;
        height: 10px !important;
        padding: 5px 10px;
    }
    .top_image_div_selected{
        width: 10px !important;
        height: 10px !important;
        padding: 5px 10px;
    }
    .middle_images{
        max-width: 120px;
        padding: 5px 0;
        padding-left: 2px;
    }
    .description{
        width: 90%;
    }
    .bottom_title{
        font-size: 0.8rem;
    }
    .bottom_right_title{
        font-size: 0.8rem;
    }
    .bottom_contact{
        font-size: 0.7rem;
    }
    .bottom_icon{
        padding: 3px;
        font-size: 0.9rem !important;
    }
    .bottom_contact_wrapper{
        margin: 5px 0;
    }
    .list_el{
        font-size: 0.7rem;
    }
    .list_el_active{
        font-size: 0.8rem;
    }
    .message_left_user_name{
        font-size: 0.8rem;
    }
    .message_img{
        width: 30px;
        height: 30px;
    }
    .user_info_number{
        font-size: 0.8rem;
    }
    .frontdrop_text{
        font-size: 0.7rem;
    }
    .frontdrop_text_action{
        padding: 7px;
        font-size: 0.8rem;
    }
    .message_enviar{
        padding: 7px;
        font-size: 0.8rem;
    }
    .message_enviar_disabled{
        padding: 7px;
        font-size: 0.8rem;
    }
}


