.reserva_list{
    color: var(--brand);
    width: 100%;
    overflow-x: hidden;
}
.list_title{
    width: 100%;
    display: flex;
    height: 80px;
    margin-top: 1px;
    justify-content: center;
    border-bottom: 1px solid white;
}
.top_title{
    font-size: 1.6rem;
    color: var(--neutral);
    font-weight: 600;
    align-self: center;
    text-transform: uppercase;
    margin-left: 10px;
}
.list{
    width: 100%;
}
.list_prox{
    width: 100%;
    height: 40px;
    background-color: #0358e590;
    display: flex;
}
.list_prox_text{
    color: var(--neutral);
    font-weight: 500;
    align-self: center;
    margin-left: 10px;
}
.item_wrapper{
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 2px solid var(--brand);
    z-index: 2;
    position: relative;
    background-color: var(--neutral);
}
.item_wrapper:hover{
    border-bottom: none;
}
.item_top{
    height: 30px;
    width: 100%;
    padding: 0 5px;
    border-radius: 10px;
}
.item{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 120px;
    justify-content: space-between;
    background-color: white;
}
.item:hover{
    border-bottom: 2px solid;
}

.item_none{
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    justify-content: center;
}
.item_left{
    display: flex;
    height: 100px;
    margin-left: 10px;
    align-self: center;
}
.item_id{
    font-size: 0.6rem;
    opacity: 0.3;
    font-style: italic;
}
.item_img{
    width: 120px;
    height: 100px;
    margin-left: 10px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.item_no_img{
    padding: 20px;
    width: 80px  !important;
    height: 60px  !important;
    margin-left: 10px;
}
.item_right{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    justify-content: space-between;
    height: 100px;
}
.top_left_indicator_more{
    display: flex;
    align-items: center;
    align-self: flex-end;
}
.more_wrapper{
    background-color: var(--brand);
    height: 30px;
    border-radius: 3px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin-left: 3px;
}
.more_wrapper:hover{
    background-color: var(--red);
}
.more{
    color: var(--neutral);
}
.remove_div{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: #000000ea;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 2px solid var(--red);
    box-sizing: border-box;
}
.center_text_div{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    color: white;
    text-align: center;
    font-size: 0.9rem;
}
.button_eliminate{
    width: 50%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 3px;
    border: 1px solid var(--red);
    text-align: center;
    padding: 5px 0;
    cursor: pointer;
    color: white;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
}
.button_cancel{
    width: 50%;
    max-width: 400px;
    margin: 5px auto;
    border-radius: 3px;
    background-color: var(--neutral);
    color: var(--brand);
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}
.button_cancel:hover{
    opacity: 0.7;
    transition: 150ms all;
}
.button_eliminate:hover{
    color: white;
    background-color: var(--red);
    transition: 150ms all;
}
.item_flex_indicator{
    display: flex;
    height: 30px;
    align-self: center;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px;
}
.item_flex{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.item_indicator{
    background-color: white;
    border-radius: 10px;
    height: 60%;
    width: 5px;
}
.item_title_div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    margin-left: 20px;
}
.item_title{
    font-weight: 600;
    font-size: 1.2rem;
}
.item_time{
    font-weight: 400;
    font-size: 0.8rem;
    opacity: 0.5;
    align-self: flex-end;
}
.item_type_tbd{
    color: #ffffff80;
    font-weight: 500;
    font-style: italic;
}

.item_type{
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    font-size: 0.9rem;
}
.item_flex_worker{
    width: 20px;
    height: 20px;
}
.item_flex_worker_text{
    text-transform: uppercase;
    align-self: flex-end;
}
.item_flex_symbol{
    width: 25px;
    height: 25px;
    color: var(--action);
}
.item_desc{
    width: 250px; 
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.8rem;
    font-weight: 400;
    white-space: pre-line;
}
.text_tbd{
    font-style: italic;
    margin-left: 5px;
}
.request_date{
    align-self: flex-end;
    color: #00000050;
    font-style: italic;
}
.dropdown_top{
    background-color: white;
    width: 100%;
    padding: 5px 2px;
    box-sizing: border-box;
}
.dropdown_top_text{
    color: #ccc;
    font-style: italic;
    padding: 5px;
}
.drop_div_symbol{
    font-size: 1rem;
}
.drop_div_main{
    width: 100%;
    cursor: pointer;
    padding: 5px 0;
}
.drop_div_main:hover{
    background-color: var(--action);

}
.drop_div_main:hover{
    color: white;
}
.drop_div{
    display: flex;
    justify-content: flex-start;
    width: 155px;
    margin: auto;
    padding: 5px;
    align-items: center;
}
.publicar{
    background-color: var(--job);
    padding: 10px;
    box-sizing: border-box;
    color: white;
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}
.publicar:hover{
    opacity: 0.7;
    transition: 150ms;
}

@media (max-width: 1024px){
    .top_title{
        font-size: 1.3rem;
    }
    .item_title{
        font-size: 1rem;
    }
}
@media (max-width: 924px){
    .item_title{
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 350px;
        white-space: nowrap;
    }
}

@media (max-width: 768px){
    .item_title{
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 250px;
        white-space: nowrap;
    }
    .item_left{
        margin-left: 0px;
    }
    .item_title_div{
        width: 150px;
    }
    .item_title{
        font-size: 0.9rem;
    }
    .item_desc{
        font-size: 0.7rem;
    }
    .item_flex_indicator{
        height: 25px;
        padding: 0 5px;
        border-radius: 3px;
    }
    .item_type{
        font-size: 0.8rem;
    }
    .more_wrapper{
        border-radius: 3px;
        height: 25px;
    }
    .more{
        font-size: 1.2rem !important;
    }
    .item_flex_worker_text{
        font-size: 0.7rem;
    }
    .item_time{
        font-size: 0.6rem;
    }
}

@media (max-width: 500px){
    .top_title{
        font-size: 1rem;
    }
    .list_title{
        height: 50px;
    }
    .list_prox_text{
        font-size: 0.9rem;
    }
    .item_img{
        width: 100px;
        height: 80px;
        margin-left: 0;
    }
    .item_title_div{
        margin-left: 5px;
        height: 80px;
        width: 200px;
    }
    .item_desc{
        max-width: 100%;
        font-size: 0.6rem;
    }
    .item_title{
        max-width: 100%;
        font-size: 0.8rem;
    }
    .item_id{
        font-size: 0.5rem;
    }
    .item{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 130px;
    }
    .item_left{
        align-self: flex-start;
        margin-top: 5px;
        margin-left: 5px;
    }
    .item_right{
        margin: 0 auto;
        margin-top: 5px;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 97%;
        box-sizing: border-box;
        padding-bottom: 5px;
    }
    .item_no_img{
        width: 100px;
        height: 80px;
        margin-left: 0;
        padding: 10px;
    }
    .item_type{
        font-size: 0.7rem;
    }
    .item_flex_worker_text{
        font-size: 0.6rem;
    }
    .item_type_tbd{
        font-size: 0.8rem;
    }
}