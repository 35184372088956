@import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap');

.subscription{
    color: var(--brand);
    overflow-x: auto !important;
    width: 100%;
    height: 100%;
    background-color: var(--brand);
    position: relative;
    /* overflow-y: hidden; */
}
.backdrop{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000aa;
    z-index: 1;
    transition: all 500ms ease-in-out;
}
.subscription_title{
    width: 100%;
    display: flex;
    height: 80px;
    background-color: white;
    margin-top: 1px;
}
.top_title{
    font-size: 1.6rem;
    color: var(--brand);
    font-weight: 600;
    align-self: center;
    text-transform: uppercase;
    margin-left: 10px;
}
.mid{
    margin: auto;
    padding-bottom: 20px;
    padding-top: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--brand);
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
    height: calc(100vh - 150px);
}
.discount{
    background-color: var(--action);
    padding: 5px 10px;
    color: white;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 5px;
}
.discount_letters_small{
    background-color: var(--action);
    padding: 5px 10px;
    color: white;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 5px;
    display: none;
}
.discount_letters{
    background-color: var(--action);
    padding: 5px 10px;
    color: white;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 5px;
}
.discount_number{
    background-color: var(--action);
    padding: 3px 5px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 2px;
    margin-left: 5px;
}
.canceled_title{
    color: var(--grey);
    font-weight: 600;
}
.sub_info_main{
    width: 100%;
}
.sub_info_wrap{
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgb(255,120,90);
    background: linear-gradient(180deg, rgba(255,120,90,1) 0%, rgba(22,31,40,1) 100%);
    transition: 'background' 150ms ease-in-out;
}
.sub_info{
    margin: 10px auto 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.sub_info_title{
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin: 30px auto 15px auto;
    font-size: 1.2rem;
}
.sub_info_title_discount{
    margin-top: -15px;
    color: var(--neutral);
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 30px;
}
.sub_info_divider{
    height: 20px;
    width: 100%;
}
.sub_info_bottom_wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}
.sub_info_bottom{
    margin-top:30px;
    /* border: 1px solid #fff; */
    border-radius: 5px;
    width: 800px;
    padding: 30px 0;
    background-color: #ffffff10;
}
.trial_button{
    padding: 10px;
    margin: 0 auto;
    background-color: var(--action);
    width: max-content;
    border-radius: 5px;
    position: relative;
    margin-top: -10px;
}
.trial_button:hover{
    opacity: 0.6;
    transition: 150ms;
    cursor: pointer;
}
.trial_button_banner{
    position: absolute;
    right: -60px;
    top: -22px;
    padding: 5px 10px;
    background-color: var(--brand);
    color: var(--neutral);
    font-weight: 500;
    font-size: 0.6rem;
    border-radius: 5px;
    border: 1px solid #ffffff30;
    box-shadow: rgba(255, 255, 255, 0.10) 0px 6px 12px -2px, rgba(255, 255, 255, 0.3) 0px 2px 3px -2px;
}
.trial_button_text{
    color: #ffffff;
    font-weight: 600;
}
.info_bottom_text_wrapper{
    display: flex;
    width: 80%;
    justify-content: center;
    margin: 0 auto;
}
.info_bottom_text{
    color: var(--neutral);
    font-size: 0.9rem;
    text-align: left;
    margin-bottom: 30px;
}
.info_bottom_text_title{
    background-color: var(--neutral);
    color: var(--brand);
    width: fit-content;
    margin: 0 auto;
    padding: 5px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
    margin-bottom: 5px;
}
.info_bottom_or{
    margin: 50px auto;
    color: #fff;
    font-weight: 500;
    width: min-content;
}
.mid_content{
    width: 800px;
    margin: auto;
    position: relative;
}
.display{
    width: 100%;
    padding: 5px 0;
    background: #ffffff10;
}
.display_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.subtitle{
    margin: 10px auto 5px auto;
    font-size: 1.3rem;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    width: max-content;
}
.ya{
    font-size: 1.1rem;
    color: var(--job);
    font-weight: 600;
    margin: 20px 0 0px 0;
    text-align: center;
}
.subtitle_sub{
    margin: 10px auto;
    font-size: 1.2rem;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 500;
    color: var(--neutral);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.divider{
    width: 100%;
    height: 1px;
    background-color: var(--grey);
    border-radius: 5px;
    margin: 5px 0;
}
.activate_info{
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.value{

}
.divider{

}
.line{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: column;
}
.line_left{
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: var(--brand);
    border-radius: 10px;
    border: 1px solid var(--action);
    justify-content: center;
    flex-direction: column;
}
.line_circle{
    width: 25px !important;
    height: 25px !important;
    color: var(--neutral);
}
.helper_text{
    color: var(--action);
    margin: 0 5px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
    display: none;
}
.line_text{
    font-size: 0.8rem;
    /* background-color: var(--brand); */
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    text-align: center;
    color: white;
    /* border: 2px solid var(--action); */
    max-width: 500px;
    margin-top: 10px;
}
.connector{
    width: 30px;
    height: 2px;
    background: rgb(255,120,90);
    background: linear-gradient(270deg, rgba(255,120,90,1) 0%, rgba(255,255,255,1) 100%);
    display: none;
}
.action{
    color: var(--action);
}
.divider_abs{
    position: absolute;
    left: 0;
    top: 100px;
    height: 1px;
    background-color: var(--grey);
    width: 100%;
}
.display_user{
    display: flex;
    width: 100%;
}
.user_img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}
.user_top_flex{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}
.user_name{
    font-size: 1.2rem;
    color: black;
}
.user_desc_top{
    color: var(--neutral);
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
}
.user_desc{
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: uppercase;
}
.user_desc_dark{
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: uppercase;
}
.user_desc_helper{
    color: white;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
}
.display_right{
    margin-left: 20px;
    display: flex;
    align-items: center;
    align-self: flex-end;
}

.display_right_icon{
    width: auto !important;
    height: 30px !important;
    border-radius: 50%;
    color: var(--check);
    padding: 0px;
}
.display_right_text{
    font-size: 0.6rem;
}
.selected_plan{
    display: flex;
    margin-top: 15px;
    align-self: flex-start;
    flex-direction: column;
    width: 100%;
}
.selected_plan_title{
    font-weight: 500;
    text-transform: uppercase;
    color: var(--neutral);
    margin: auto;
    margin-top: 50px;
    font-size: 1rem;
    margin-bottom: 5px;
}
.selected_plan_discount_wrap{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.selected_plan_value_wrap{
    width: 80%;
    text-align: left;
}
.selected_plan_value{
    color: var(--neutral);
    font-size: 0.9rem;
}
.selected_plan_value_normal{
    margin: 0 5px;
    color: var(--action);
    font-weight: 600;
}
.selected_plan_value_information{
    font-size: 0.8rem;
    margin-top: 10px;
    font-weight: 400;
    color: #ffffff;
}
.days_top{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.days_top_small{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.days_top_value_small{
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--neutral);
}
.days_top_title_main{
    color: var(--neutral);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.3rem;
}
.top_background{
    background-color: var(--brand);
    padding: 1px 5px;
    border-radius: 3px;
}
.days_top_title_help{
    color: var(--neutral);
    font-weight: 500;
    font-size: 0.8rem;
}
.days_top_value_small_number_font{
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--neutral);
    transition: 150ms all;
}
.days_top_discount{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.days_top_discount_info{
    color: var(--action);
    font-weight: 600;
    margin-top: 5px;
}
.days_top_value_small_number{
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--neutral);
    font-family: 'Roboto Mono', monospace;
    transition: 150ms all;
}
.top_background_flex{
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
}
.days_top_title_small{
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--action);
    text-transform: uppercase;
    margin-left: 5px;
}
.days_top_value{
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--neutral);
}
.days_top_title{
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--neutral);
}
.selected_plan_no_value{
    color: var(--neutral);
    font-size: 0.9rem;
    margin: 30px auto;
    background: #0358e590;
    padding: 10px 20px;
    border-radius: 5px;
    width: max-content;
    text-align: center;
    box-sizing: border-box;
}
.selected_plan_info{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.info_div_pay{
    margin: 0 auto 20px auto;
    background-color: var(--brand);
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
}
.info_div{
    display: flex;
    align-items: center;
    align-self: flex-start;
}
.info_icon{
    width: 10px !important;
    height: 10px !important;
    color: var(--job);
}
.info_subdiv{
    display: flex;
    align-items: center;
}
.future_end{
    color: var(--neutral);
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 10px;
}
.history{
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 30px;
}
.operations_wrapper{
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: var(--neutral);
    text-transform: uppercase;
}
.title_operations{

}
.history_button{
    width: 100%;
    padding: 10px 0;
    position: relative;
    cursor: pointer;
}
.history_button:hover{
    opacity: 0.8;
    transition: 150ms all;
}
.history_title{
    color: var(--neutral);
    text-align: center;
    text-transform: uppercase;
}
.history_title_small{
    color: var(--neutral);
    text-align: center;
    text-transform: uppercase;
    display: none;
}
.history_map{
    box-sizing: border-box;
    max-height: 280px;
    overflow-y: auto;
}

.history_element{
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--neutral);
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.history_element:first-child{
    margin-top: 5px;
}
.history_element:last-child{
    margin-bottom: 0;
}
.history_image{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--action);
    border-radius: 5px;
    height: 40px;
    width: 40px;
    border: 2px solid var(--action);
}
.history_image_value{
    width: 25px !important;
    height: 25px !important;
}
.history_right{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 45px;
}
.history_right_west{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5px;
}
.history_west_title{
    font-weight: 700;
    color: var(--action);
}
.history_west_value{
    font-weight: 600;
    text-align: left;
}
.history_right_east{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.history_east_date{
    font-size: 0.9rem;
}
.history_temporary{
    display: flex;
    align-items: center;
    background-color: #fdd835;
    padding: 2px 10px;
    border-radius: 20px;
    cursor: pointer;
    width: fit-content;
    border: 1px solid var(--yellow);
    -webkit-box-shadow: 0px 0px 55px -10px rgba(255,255,255,1);
-moz-box-shadow: 0px 0px 55px -10px rgba(255,255,255,1);
box-shadow: 0px 0px 55px -10px rgba(255,255,255,1);
}
.history_temporary:hover{
    opacity: 0.8;
    transition: 150ms all;
}
.temporary_image{}
.temporary_image_val{
    width: 25px !important;
    height: 30px;
}
.temporary_east{
    margin-left: 5px;
}
.temporary_east_full{
    margin-left: 5px;
}
.temporary_east_title{
    font-size: 0.9rem;
    font-weight: 600;
}
.temporary_east_date{
    font-size: 0.8rem;
    /* font-weight: 600; */
}


.info_text_helper{
    font-weight: 400;
    text-transform: uppercase;
    color: var(--neutral);
    width: 85px;
    font-size: 0.9rem;
    text-align: left;
}
.info_text_helper_date{
    font-weight: 400;
    color: var(--neutral);
    font-size: 0.9rem;
    text-align: center;
    margin: 0 auto;
    font-weight: 400;
}
.display_three_canceled{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.display_three_button{
    background-color: var(--action);
    color: white;
    padding: 10px 20px;
    width: fit-content;
    align-self: center;
    margin-top: 20px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}
.display_three_button:hover{
    opacity: 0.8;
    transition: 150ms;
}
.info_text{
    font-size: 1rem;
    font-weight: 600;
    color: white;
}
.info_text_days{
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
}
.info_text_small{
    font-size: 1rem;
    font-weight: 600;
    color: white;
}
.info_text_days_charge{
    font-size: 0.8rem;
    font-weight: 400;
    color: white;
    font-style: italic;
}
.display_sub{
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    align-self: flex-start;
    height: 90px;
}
.helper_two{
    font-size: 0.7rem;
    margin-left: 5px;
    opacity: 1;
    flex-wrap: nowrap;
    word-wrap: break-word;
}
.changing_plan{
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 15px;
}
.prox_cobr{
    font-size: 0.9rem;
    width: fit-content;
    text-transform: uppercase;
    margin: auto;
    font-weight: 500;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.prox_cobr_val{
    color: var(--action);
    font-size: 1rem;
    margin-top: 10px;
}
.discount_2{
    background-color: var(--brand);
    padding: 1px 6px;
    border-radius: 3px;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 5px;
}
.discount_text_2{
    font-family: "Caveat", cursive;
    font-size: 1.4rem;
    color: var(--neutral);
}
@keyframes color {
    0% {
      color: #FF785A;
    }
    50% {
        color: #FF785A50;
    }
    100% {
        color: #FF785A;
    }
}
.cancel{
    font-size: 0.8rem;
    margin-top: 15px;
    opacity: 0.8;
}
.resub{
    font-size: 0.8rem;
    margin-top: 15px;
    font-weight: 600;
}
.plan_meio{
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    border: 1px solid var(--action);
    padding: 10px;
    border-radius: 5px;
    background-color: var(--action);
}
.sub_pre{
    color: white;
    font-size: 0.9rem;
}
.sub_val_wrap_hover{
    cursor: pointer;
}
.sub_val_wrap_hover:hover{
    background-color: var(--job) !important;
    transition: 150ms;
}
.sub_val_wrap_small{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0358e590;
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
    border: 3px solid var(--job);
    box-sizing: border-box;
    position: relative;
}
.sub_val_wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0358e590;
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
    border: 3px solid var(--job);
    box-sizing: border-box;
    position: relative;
}
.sub_val_wrap_image{
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 5px;
    padding-right: 7px;
    background-color: var(--job);
    border-bottom-right-radius: 5px;
}
.sub_val_date{
    font-size: 1.1rem;
    color: var(--neutral);
    text-transform: uppercase;
    font-weight: 600;
}
.sub_val_date_discount{
    font-size: 0.7rem;
    color: var(--neutral);
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--action);
    border-radius: 2px;
    padding: 5px;
}
.sub_val_date_discount_small{
    font-size: 0.7rem;
    color: var(--neutral);
    text-transform: uppercase;
    font-weight: 600;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--action);
    border-radius: 2px;
    padding: 5px;
    display: none;
}
.plano_text{
    font-size: 0.9rem;
}
.plano_text_helper{
    color: var(--grey);
    font-size: 0.8rem;
    font-style: italic;
}
.plano_text_val{
    font-weight: 600;
}
.details{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    transition: all 400ms ease-in-out;
    opacity: 1;
}
.details_hide{
    transform: translateX(800px);
    overflow: hidden;
    transition: all 400ms ease-in-out;
    opacity: 0;
    position: absolute;
}
.ver_planos{
    font-size: 0.6rem;
    text-decoration: underline;
    color: #0073C1;
    cursor: pointer;
}
.ver_planos:hover{
    opacity: 0.8;
}
.details_top{
    display: flex;
}
.details_area{
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}
.card{
    border-radius: 10px;
    padding: 20px 20px;
    width: 300px;
    /* background: rgb(254,137,147);
    background: linear-gradient(135deg, rgba(254,137,147,1) 0%, rgba(254,170,141,1) 100%); */
    /* background: rgb(255,120,90);
    background: linear-gradient(135deg, rgba(255,120,90,1) 0%, rgba(254,170,141,1) 100%); */
    background: rgb(3,88,229);
background: linear-gradient(90deg, rgba(3,88,229,1) 0%, rgba(63,128,235,1) 100%);
    font-family: 'Gugi', cursive;
    margin-top: 30px;
    height: 155px;
}
.card_top{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}
.main_page{
    background-color: white;
}
.brand{
    height: 20px;
    width: auto;
}
.brand_master{
    height: 20px;
    border-radius: 5px;
    transform: scale(1.5);
    margin-right: 5px;
}
.brand_american{
    height: 20px;
    transform: scale(2.2);
    margin-right: 10px;
}
.card_mid{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
}
.chip{
    height: 35px;
    width: auto;
}
.card_number{
    margin-top: 5px;
}
.card_number_value{
    color: white;
    word-spacing: 2px;
    letter-spacing: 1px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.289);
    font-size: 1.2rem;
}
.card_name_date{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}
.card_name{
    display: flex;
    flex-direction: column;
}
.name_helper{
    color: #ffffff50;
    font-size: 0.7rem;
}
.name_val{
    color: white;
    font-size: 0.8rem;
    font-weight: 400;
}
.details_right{
    margin-left: 20px;
}
.details_title{
    align-self: flex-start;
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    padding-top: 30px;
}
.right_sections_div{
    height: 190px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 30px;
    font-family: 'Gugi', cursive;
}
.right_section{
    display: flex;
    flex-direction: column;
    position: relative;
}
.card_wrong{
    color: var(--red);
    font-size: 0.8rem;
    margin-top: 5px;
}
.right_helper{
    color: var(--grey);
    font-size: 0.8rem;
}
.right_section_short_div{
    display: flex;
    justify-content: space-between;
}
.right_section_short{
    display: flex;
    flex-direction: column;
    position: relative;
}
.right_input{
    border: none;
    outline: none;
    border-bottom: 1px solid var(--grey);
    font-size: 1rem;
    width: 100%;
    padding: 5px 0;
    background-color: var(--brand);
    color: white !important;
}
.right_input::placeholder{
    color: var(--grey);
    opacity: 0.8;
    font-style: italic;
    font-size: 0.9rem;
}
.right_input_short::placeholder{
    color: var(--grey);
    opacity: 0.8;
    font-style: italic;
    font-size: 0.9rem;
}
.right_input:focus{
    border-color: var(--action);
}
.right_input_short:focus{
    border-color: var(--action);
}
.right_input_short{
    border: none;
    outline: none;
    border-bottom: 1px solid var(--grey);
    font-size: 1rem;
    width: 80px;
    padding: 5px 0;
    color: white;
}
.fontFam{
    font-family: 'Roboto Mono', monospace;
}
.sub_val_subscribed{
    animation-name: blue;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.sub_val_not_subscribed{
    background-color: #fdd83540;
    border-color: var(--yellow);
}


@keyframes blue {
    0% {
      background-color: #0358e540;
    }
    50% {
        background-color: #0358e5aa;
    }
    100% {
        background-color: #0358e540;
    }
}
.buttons{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 50px;
}
.saver_color{
    color: #ffffffa0;
}
.button_add_disabled{
    width: 70%;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    background-color: var(--action);
    color: rgb(255, 222, 222);
    cursor: pointer;
    text-transform: uppercase;
    transition: all 150ms;
    opacity: 0.5;
    cursor: default;
}
.button_add{
    width: 70%;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    background-color: var(--action);
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 150ms;
    font-weight: 600;
}
.button_add:hover{
    opacity: 0.8;
}
.button_cancel{
    width: 30%;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    background-color: var(--neutral);
    margin-left: 10px;
    color: var(--brand);
    cursor: pointer;
    font-weight: 600;
}
.button_cancel:hover{
    opacity: 0.8;
}

.indicator_div{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    background-color: #ffffff10;
    padding: 10px 0;
    border-radius: 2px;
    box-sizing: border-box;
}
.indicator_div_plan_selected{
    background: rgb(3,88,229);
    background: linear-gradient(90deg, rgba(3,88,229,1) 0%, rgba(26,103,231,1) 18%, rgba(88,144,237,1) 30%, rgba(113,160,240,1) 39%, rgba(228,237,252,1) 50%, rgba(228,237,252,1) 80%, rgba(255,255,255,1) 100%);
}
.indicator_subdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    cursor: pointer;
    position: relative;
    min-width: max-content;
}
.indicator{
    height: 3px;
    border-radius: 20px;
    width: 100%;
    background-color: var(--grey);
}
.indicator_text{
    font-size: 0.8rem;
    color: var(--grey);
    text-align: center;
    font-family: 'Roboto Mono', monospace;
}
.indicator_check{
    width: 20px !important;
    height: 20px !important;
    color: var(--job);
    position: absolute;
    top: -20px;
}
.display_one{
    display: inline-block;
    position: relative;
    width: 100%;
    transition: all 400ms ease-in-out;
    overflow: hidden;
    margin-top: 10px;
    opacity: 1;
    overflow-y: hidden;
}
.display_one_hide{
    opacity: 0;
    transition: all 400ms ease-in-out;
}
.plans{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition:  all 400ms ease-in-out;
}
.plans_hide{
    transform: translateX(-800px);
    overflow: hidden;
    transition: all 400ms ease-in-out;
    opacity: 0;
    position: absolute;
}
.plans_area{
    height: 275px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.plans_title{
    font-weight: 500;
    display: inline-block;
    text-transform: uppercase;
    color: white;
    font-size: 1rem;
    margin-top: 30px;
}
.plans_sections{
    width: 98%;
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin-top: 10px;
    margin: 30px auto;
}
.popular{
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 0.6rem;
    color: white;
    border-radius: 3px;
    background-color: var(--brand);
    font-weight: 500;
    padding: 2px 4px;
}
.ativo{
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 0.7rem;
    color: white;
    border-radius: 2px;
    background-color: var(--neutral);
    font-weight: 500;
    color: var(--brand);
    text-transform: uppercase;
    font-weight: 600;
    padding: 3px 6px;
}
.section{
    height: 100%;
    border-radius: 5px;
    background-color: #0358e590;
    width: 31%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    position: relative;
    box-sizing: border-box;
    border: 3px solid transparent;
}
.section_selected{
    height: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #0358e5;
    width: 32%;
    display: flex;
    flex-direction: column;
    transform: scale(1.05);
    border: 3px solid var(--job);
    cursor: pointer;
    position: relative;
    transition: ease-in-out 150ms transform;
}
.section_worker{
    height: 100%;
    border-radius: 5px;
    /* background-color: #0358e5bb; */
    background-color: var(--brand);
    width: 31%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    position: relative;
    box-sizing: border-box;
}
.section:hover{
    cursor: pointer;
    transition: 150ms;
    transform: scale(1.05);
}
.section_img{
    width: 40px !important;
    height: 40px !important;
    margin: 20px auto;
}
.section_img_small{
    width: 25px !important;
    height: 29px !important;
}
.section_type{
    font-weight: 700;
    margin: 0 auto;
    font-size: 1.5rem;
    color: var(--neutral);
    text-align: center;
}
.section_type_desc{
    color: #ffffffaa;
    margin: 0 auto;
    font-size: 0.7rem;
    text-align: center;
    font-style: italic;
}
.section_valor_div{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20px;
}
.section_valor_top{
    display: flex;
    color: var(--brand);
    width: 70px;
    display: flex;
    justify-content: center;
}
.section_valor_top_symbol{
    width: 15px !important;
    height: 15px !important;
    color: var(--action);
}
.section_valor_top_number{
    font-size: 2rem;
    font-weight: 700;
    color: var(--action);
}
.section_valor_top_number_decimal{
    font-size: 1rem;
    font-weight: 600;
    color: var(--action);
}
.section_valor_top_symbol_discount{
    width: 15px !important;
    height: 15px !important;
    color: var(--grey);
}
.section_valor_top_number_discount{
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: line-through;
    color: var(--grey);
}
.previous{
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    width: fit-content;
}
.previous:hover{
    transition: 150ms all;
    opacity: 0.8;
}
.previous_symbol{
    color: var(--neutral);
}
.previous_text{
    text-transform: uppercase;
    font-weight: 600;
    color: var(--neutral);
}
.section_valor_top_number_decimal_discount{
    font-size: 0.8rem;
    font-weight: 500;
    text-decoration: line-through;
    color: var(--grey);
}
.section_discount{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.discount_arrow{
    color: var(--action);
    width: 30px !important;
    height: 30px !important;
}
.discount_arrow_small{
    color: var(--neutral);
    width: 20px !important;
    height: 20px !important;
    margin-left: 5px;
}
.section_desc_of_pay{
    color: var(--neutral);
    font-size: 0.8rem;
    margin: 0 auto;
    width: 70px;
    font-weight: 400;
    text-align: center;
}
.section_desc_of_pay_discount{
    color: grey;
    font-size: 0.8rem;
    width: 70px;
    text-decoration: line-through;
    text-align: center;
    font-weight: 600;
}
.section_desc_of_pay_discount_new{
    color: white;
    font-size: 0.7rem;
    margin-left: 5px;
    width: 70px;
    text-align: center;
}
.section_desc_of_pay_discount_bottom{
    color: var(--neutral);
    font-size: 0.8rem;
    text-decoration: line-through;
    text-align: center;
    margin-left: 5px;
}
.discount_arrow_small_bottom{
    color: var(--neutral);
    width: 20px !important;
    height: 20px !important;
}
.section_desc_of_pay_discount_new_bottom{
    color: var(--action);
    font-size: 0.9rem;
    text-align: center;
    margin-right: 5px;
    font-weight: 600;
}

.section_button{
    color: var(--brand);
    margin: auto;
    border-radius: 3px;
    background-color: white;
    padding: 1px 5px;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}
.section_button_active{
    color: white;
    margin: auto;
    padding: 1px 5px;
    font-size: 0.9rem;
    font-weight: 500;
}
.section_button_selected{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    color: white;
    margin: auto;
}
.section_button_selected_icon{
    width: 20px !important;
    height: 20px !important;
}
.section_button_selected_text{
    font-size: 0.8rem;
    margin-top: -2px;
    font-weight: 600;
}
.top_complete_arrow{
    width: 20px !important;
    height: 20px !important;
    transition: all 150ms;
    color: var(--action);
    position: absolute;
    right: 5px;
}
.top_complete_arrow_show{
    width: 20px !important;
    height: 20px !important;
    transform: rotate(90deg);
    transition: all 150ms;
    color: var(--action);
    position: absolute;
    right: 5px;
}
.initial{
    width: 100%;
    display: flex;
    justify-content: center;
}
.card_right{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 40px;
}
.card_right_title{

}
.card_right_bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.card_right_button{
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    background-color: var(--action);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    margin-top: 0px;
}
.card_right_button:hover{
    opacity: 0.8;
    transition: 150ms;
    cursor: pointer;
}
.card_right_button_remove{
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    background-color: var(--neutral);
    color: var(--brand);
    font-weight: 600;
    height: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    text-transform: uppercase;
}
.card_right_button_remove:hover{
    opacity: 0.6;
    transition: 150ms;
    cursor: pointer;
}
.card_right_button_remove_plan{
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    border: 2px solid var(--action);
    background-color: white;
    color: var(--brand);
    font-weight: 600;
    height: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    text-transform: uppercase;
}
.card_right_button_remove_plan:hover{
    color: var(--brand);
    transition: 150ms;
    cursor: pointer;
}
.display_zero{
    height: 100%;
    transition: all 400ms ease-in-out;
    margin-top: 20px;
}
.display_zero_hide{
    height: 0;
    transition: all 400ms ease-in-out;
}
.alterar_plano_wrap{
    width: 100%;
    margin-top: 100px;
    margin-bottom: -50px;
}
.alterar_plano{
    margin-top: 5px;
    font-size: 0.8rem;
    color: white;
}
.alterar_plano_date{
    color: var(--neutral);
    font-weight: 700;
    background-color: var(--job);
    border-radius: 5px;
    padding: 5px;
    width: max-content;
}
.arrow{
    margin: 0px 5px;
    color: white;
    font-size: 40px;
    background: rgb(3,88,229);
    background: linear-gradient(90deg, rgba(3,88,229,1) 0%, rgba(255,120,90,1) 100%);
    border-radius: 5px;
    padding: 0px 3px;
}
.change_plan_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}
.options{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
}
.options_card{
    background-color: #0358e590;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    border-radius: 5px;
    padding: 20px 0px 10px 0;
    position: relative;
    border: 2px solid var(--job);
}
.options_card_discount{

}
.options_card_discount{

}
.options_card_title{
    font-weight: 600;
    color: var(--neutral);
    margin: 5px 0;
    margin-top: 10px;
    text-align: center;
}
.options_card_title_helper{
    color: #ffffffaa;
    margin: 0 auto;
    font-size: 0.7rem;
    margin: 0 0;
    font-style: italic;
    margin-bottom: 10px;
    text-align: center;
}
.options_card_img{
    width: 35px !important;
    height: 35px !important;
}
.options_price_flex{
    display: flex;
    align-items: center;
}
.options_price_flex_flex{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--grey);
}
.price_euro{
    width: 15px !important;
    height: 15px !important;
}
.options_price_value{
    font-size: 1rem;
    text-decoration: line-through;
}
.options_price_decimal{
    font-size: 0.5rem;
    text-decoration: line-through;
}
.options_price_value_new{
    font-size: 1.1rem;
}
.options_price_decimal_new{
    font-size: 0.6rem;
}
.price_arrow{
    color: var(--action);
}
.bottom_title{
    color: var(--neutral);
    text-transform: uppercase;
    /* text-decoration: underline; */
    text-underline-offset: 1px;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 20px;
}
@media (min-width: 1440px){
    .sub_info_title{
        font-size: 1.6rem;
    }
    .helper_text{
        font-size: 1rem;
    }
    .line_text{
        font-size: 0.9rem;
    }
    .info_bottom_text{
        font-size: 1.1rem;
    }
    .trial_button_text{
        font-size: 1.1rem;
    }
    .trial_button_banner{
        font-size: 0.7rem;
    }
    .sub_info_bottom{
        width: 900px;
    }
}

@media (max-width: 1024px){
    .top_title{
        font-size: 1.3rem;
    }
    .line_text{
        width: 400px;
    }
    .sub_info_bottom{
        width: 600px;
    }
    .mid_content{
        width: 90%;
    }
    .sub_val_date_discount_small{
        display: block;
    }
    .sub_val_date_discount{
        display: none;
    }
    .discount_letters{
        display: none;
    }
    .discount_letters_small{
        display: block;
    }
    .days_top_value{
        font-size: 1.4rem;
    }
    .days_top_title{
        font-size: 1.1rem;
    }
}

@media (max-width: 768px){
    .history_temporary{
        padding: 5px 5px;
    }
    .temporary_image_val{
        width: 20px !important;
        height: 24px;
    }
    .temporary_east{
        display: none;
    }
    .top_title{
        font-size: 1.1rem;
    }
    .alterar_plano_wrap{
        padding: 0 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .selected_plan_value_information{
        margin-left: 5px;
    }
    .helper_text{
        font-size: 0.7rem;
    }
    .line_text{
        font-size: 0.6rem;
        max-width: 300px;
    }
    .sub_info_bottom{
        width: 450px;
    }
    .section_valor_top_number{
        font-size: 1.4rem;
    }
    .section_valor_top_number_discount{
        font-size: 1.3rem;
    }
    .section_valor_top_number_decimal{
        font-size: 0.8rem;
    }
    .section_valor_top_number_decimal_discount{
        font-size: 0.6rem;
    }
    .discount_arrow{
        width: 15px !important;
        height: 15px !important;
    }
    .discount_arrow_small{
        width: 10px !important;
        height: 10px !important;
    }
    .section_desc_of_pay_discount{
        font-size: 0.6rem;
    }
    .section_desc_of_pay_discount_new{
        font-size: 0.6rem;
    }
    .section_img{
        width: 30px !important;
        height: 30px !important;
    }
    .section_type{
        font-size: 1.1rem;
    }
    .section_type_desc{
        font-size: 0.55rem;
    }
    .section_valor_div{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .discount{
        padding: 5px 7px;
        font-size: 0.7rem;
    }
    .alterar_plano{
        font-size: 0.7rem;
    }
    .options_card_title_helper{
        font-size: 0.7rem;
    }
    .days_top_value{
        font-size: 1.3rem;
    }
    .days_top_title{
        font-size: 1rem;
    }
    .operations_wrapper{
        font-size: 1rem;
    }
}
.verificar_top_wrapper{
    width: 100%;
    background-color: var(--brand);
    border-radius: 3px;
    margin-top: 10px;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
}
.verificar_top{
    width: 100%;
    background-color: var(--yellow);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    background-color: var(--yellow);
    animation-name: yellow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    padding: 3px 0;
    
}
.input_div_button_text_no_animation{
    color: white;
    font-size: 0.9rem;
    background: var(--yellow);
    padding: 2px 5px;
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 25px;
    font-weight: 600;
}

@keyframes yellow {
    0% {
      background-color: #fdd835;
    }
    50% {
        background-color: #fdd83540;
    }
    100% {
        background-color: #fdd835;
    }
}

@media (max-width: 500px){
    .temporary_east_title{
        font-size: 0.8rem;
    }
    .temporary_east_date{
        font-size: 0.7rem;
    }
    .operations_wrapper{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.9rem;
    }
    .days_top_value{
        font-size: 1.1rem;
    }
    .days_top_title{
        font-size: 0.9rem;
    }
    .top_complete_arrow{
        width: 15px !important;
        height: 15px !important;
        top: 5px;
    }
    .top_complete_arrow_show{
        width: 15px !important;
        height: 15px !important;
        top: 5px;
    }
    .verificar_top{
        padding: 2px 0;
    }
    .input_div_button_text_no_animation{
        font-size: 0.7rem;
    }
    .discount_2{
        padding: 1px 4px;
    }
    .discount_text_2{
        font-size: 1.1rem;
    }
    .info_bottom_text_title{
        padding: 5px;
        font-size: 0.7rem;
    }
    .line_left{
        padding: 6px;
        background-color: var(--brand);
    }
    .line_circle{
        width: 20px !important;
        height: 20px !important;
    }
    .alterar_plano_wrap{
        margin-top: 220px;
    }
    .info_text_small{
        display: block;
    }
    .sub_val_wrap_small{
        width: 90%;
        margin: auto;
    }
    .arrow{
        transform: rotate(90deg);
        margin: 5px;
    }
    .change_plan_wrap{
        flex-direction: column;
    }
    .options_card_title{
        font-size: 0.7rem;
    }
    .options_card_title_helper{
        font-size: 0.6rem;
    }
    .options_card_img{
        width: 25px !important;
        height: 25px !important;
    }
    .price_euro{
        width: 10px !important;
        height: 10px !important;
    }
    .options_price_value{
        font-size: 0.7rem;
        text-decoration: line-through;
    }
    .options_price_decimal{
        font-size: 0.35rem;
        text-decoration: line-through;
    }
    .options_price_value_new{
        font-size: 0.9rem;
    }
    .options_price_decimal_new{
        font-size: 0.4rem;
    }
    .price_arrow{
        color: var(--action);
        width: 15px !important;
        height: 15px !important;
    }
    .section_valor_div{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .section_discount{
        justify-content: center;
    }
    .section_valor_top{
        width: max-content;
    }
    .discount_arrow{
        width: 15px !important;
        height: 15px !important;
    }
    .discount_arrow_small{
        display: none !important;
    }
    .section_desc_of_pay_discount{
        font-size: 0.5rem;
        display: none;
    }
    .section_desc_of_pay_discount_new{
        font-size: 0.5rem;
    }
    .sub_info_title_discount{
        font-size: 0.6rem;
    }
    .info_bottom_text{
        font-size: 0.6rem;
    }
    .sub_info{
        width: 100%;
    }
    .connector{
        display: none;
    }
    .line{
        width: 100%;
        flex-direction: column;
    }
    .line_text{
        width: 100%;
        max-width: none;
        box-sizing: border-box;
        border-radius: 0px;
        border-width: 2px;
        border-left: none;
        border-right: none;
        font-size: 0.6rem;
    }
    .info_bottom_or{
        margin: 30px auto;
        font-size: 0.8rem;
    }
    .helper_text{
        color: var(--neutral);
        font-size: 0.6rem;
    }
    .sub_info_bottom{
        width: 100%;
    }
    .trial_button{
        padding: 7px;
    }
    .trial_button_text{
        font-size: 0.7rem;
    }
    .trial_button_banner{
        font-size: 0.6rem;
        right: -30px;
        top: -18px;
    }
    .section_desc_of_pay_discount_bottom{
        font-size: 0.6rem;
    }
    .discount_arrow_small_bottom{
        width: 15px !important;
        height: 15px !important;
    }
    .section_desc_of_pay_discount_new_bottom{
        font-size: 0.7rem;
    }
    .discount{
        padding: 5px 5px;
        font-size: 0.5rem;
    }
    .sub_val_date_discount{
        font-size: 0.6rem;
    }
    .top_title{
        font-size: 1rem;
    }
    .subscription_title{
        height: 50px;
    }
    .user_desc_top{
        font-size: 0.6rem;
    }
    .user_desc{
        font-size: 0.9rem;
    }
    .subtitle{
        margin: auto;
        width: max-content;
        display: block;
        font-size: 1rem;
    }
    .subtitle_sub{
        font-size: 1rem;
        justify-content: center;
        align-items: center;
    }
    .ya{
        font-size: 1rem;
    }
    .sub_info_title{
        font-size: 1rem;
        margin-top: 15px;
    }
    .indicator_text{
        font-size: 0.6rem;
    }
    .plans_title{
        width: max-content;
        margin: 30px auto 0 auto;
        font-size: 0.9rem;
    }
    .section_img{
        width: 30px !important;
        height: 30px !important;
    }
    .section_type{
        font-size: 1rem;
    }
    .section_type_desc{
        font-size: 0.55rem;
        width: 90%;
    }
    .section_valor_top_symbol{
        width: 12px !important;
        height: 12px !important;
    }
    .section_valor_top_number{
        font-size: 1rem;
    }
    .section_valor_top_number_decimal{
        font-size: 0.6rem;
    }
    .section_valor_top_number_discount{
        font-size: 0.7rem;
    }
    .section_valor_top_number_decimal_discount{
        font-size: 0.4rem;
    }
    .section_desc_of_pay{
        font-size: 0.5rem;
        text-align: center;
    }
    .selected_plan_title{
        margin-top: 10px;
    }
    .selected_plan_no_value{
        margin-top: 0px;
        font-size: 0.7rem;
        width: 90%;
    }
    .sub_val_wrap_image{
        width: 25px !important;
        height: 25px !important;
    }
    .sub_val_wrap{
        width: 90%;
        margin: auto;
    }
    .section_img_small{
        width: 20px !important;
        height: 20px !important;
        margin-left: -3px;
        margin-top: -3px;
    }
    .sub_val_date{
        font-size: 0.9rem;
    }
    .selected_plan_value{
        font-size: 0.6rem;
    }
    .history_title{
        font-size: 0.9rem;
        display: none;
    }
    .history_title_small{
        display: block;
        font-size: 0.9rem;
    }
    .history_button{
        padding: 5px 0;
    }
    .selected_plan_discount_wrap{
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .selected_plan_value_normal{
        font-size: 0.7rem;
    }
    .selected_plan_value_information{
        font-size: 0.6rem;
        margin-left: 0px;
    }
    .buttons{
        flex-direction: column;
    }
    .button_add{
        width: 90%;
        box-sizing: border-box;
        font-size: 0.8rem;
        margin: auto;
    }
    .button_cancel{
        width: 90%;
        box-sizing: border-box;
        padding: 5px 10px;
        margin: auto;
        margin-top: 5px;
        font-size: 0.8rem;
    }
    .subscription{
        height: 100%;
    }
    .button_add_disabled{
        width: 90%;
        box-sizing: border-box;
        font-size: 0.8rem;
        margin: auto;
    }
    .info_text_helper{
        font-size: 0.7rem;
    }
    .info_text{
        font-size: 0.8rem;
        width: max-content;
    }
    .info_text_days{
        font-size: 0.9rem;
        width: max-content;
    }
    .info_text_helper_date{
        font-size: 0.7rem;
    }
    .info_text_days_charge{
        font-size: 0.6rem;
    }
    .card_right_button{
        padding: 10px;
        font-size: 0.9rem;
        width: 90%;
    }
    .equivalente{
        font-size: 0.5rem !important;
        width: max-content;
        display: none !important;
    }
    .details_title{
        align-self: center;
        font-size: 0.9rem;
    }
    .right_input{
        font-size: 0.8rem;
        width: 100%;
    }
    .right_input::placeholder{
        font-size: 0.8rem;
    }
    .card{
        display: none;
    }
    .details_right{
        width: 100%;
        box-sizing: border-box;
        margin-left: 0;
        padding: 0 10px;
        border: 2px solid white;
        border-radius: 5px;
    }
    .right_section_short_div{
        justify-content: flex-start;
        margin-bottom: 5px;
    }
    .right_input_short{
        width: 100px;
    }
    .right_Section_short_helper{
        margin-top: 2px;
        margin-left: 10px;
    }
    .post_pay{
        margin-top: 20px;
    }
    .canceled_title{
        font-size: 0.8rem;
    }
    .top_background_flex{
        flex-direction: column;
    }
    .days_top_title_small{
        margin-left: 0;
        margin-top: 2px;
        font-size: 0.8rem;
    }
    .days_top_title_main{
        font-size: 1rem;
    }
    .days_top_title_help{
        font-size: 0.7rem;
    }
    .days_top_value_small_number_font{
        font-size: 1rem;
    }
    .days_top_value_small_number{
        font-size: 1rem;
    }
    .history{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .history_east_date{
        font-size: 0.7rem;
    }
    .history_west_title{
        font-size: 0.9rem;
    }
    .history_west_value{
        font-size: 0.9rem;
    }
}
