.verification{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 300px;
    display: flex;
    z-index: 2;
    justify-content: center;
}
.backdrop{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000aa;
    z-index: 1;
    transition: all 500ms ease-in-out;
}
.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--brand);
    padding: 25px;
    border-radius: 5px;
    border: 3px solid var(--yellow);
    width: 450px;
    height: max-content;
    min-height: 180px;
}
.main_inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}
.title_separator{
    width: 100%;
    height: 1px;
    background-color: var(--grey);
    margin: 5px;
}
.title{
    color: var(--neutral);
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
}
.phone_description{
    color: var(--neutral);
    text-align: center;
    font-size: 0.9rem;
    margin-top: 15px;
}
.error_description{
    color: var(--red);
    text-align: center;
    font-size: 0.9rem;
    margin-top: 15px;
}
.phone_wrapper{
    margin: auto;
    margin-top: 15px;
    font-size: 1.2rem;
}
.phone_prefix{
    color: var(--grey);
}
.phone_value{
    font-weight: 600;
    color: #fff;
}
.button_disabled{
    background-color: var(--grey);
    padding: 10px 0;
    border-radius: 5px;
    text-align: center;
    width: 350px;
    align-self: center;
    margin-top: 40px;
    color: var(--neutral);
    transition: all 150ms;
    cursor: default;
}
.button{
    background-color: var(--yellow);
    padding: 10px 0;
    border-radius: 5px;
    text-align: center;
    width: 350px;
    align-self: center;
    margin-top: 40px;
    color: var(--neutral);
    transition: all 150ms;
}
.button:hover{
    cursor: pointer;
    opacity: 0.8;
    transition: 150ms;
}
.button_text{
    font-weight: 600;
    text-transform: uppercase;
    color: var(--neutral);
}
.resend{
    background-color: var(--yellow);
    border-radius: 5px;
    text-align: center;
    height: 25px;
    width: 350px;
    align-self: center;
    margin-top: 5px;
    color: var(--brand);
    transition: all 150ms;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
}
.resend_text{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.resend_disabled{
    background-color: var(--grey);
    height: 25px;
    border-radius: 5px;
    text-align: center;
    width: 350px;
    align-self: center;
    margin-top: 5px;
    color: var(--neutral);
    transition: all 150ms;
}
.resend_text_value{
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
}
.resend:hover{
    transform: scale(1.01);
    cursor: pointer;
}
.resend_seconds{
    width: 25px;
    display: block;
    font-family: 'Roboto Mono', monospace;
}
.resend_seconds_separator{
    height: 80%;
    width: 2px;
    background-color: var(--neutral);
    margin: 0 15px;
}
.cancel{
    margin: auto;
    margin-top: 10px;
    color: var(--grey);
    font-size: 0.9rem;
    cursor: pointer;
    text-transform: uppercase;
}
.cancel:hover{
    opacity: 0.5;
    transition: all 150ms;
}
.phone_input_wrapper{
    display: flex;
    margin: auto;
    justify-content: center;
    margin-top: 5px;
    padding: 5px;
    border: 3px solid var(--job);
    border-radius: 3px;
    position: relative;
    z-index: 0;
    margin-bottom: 20px;
}
.main_code_placeholder{
    position:absolute;
    left:0;
    top:5px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-around;
    width:100%;
    z-index:1;
}
.main_code_placeholder_value{
    color:#ffffff30;
    font-size:1.4rem;
    font-family: 'Roboto Mono', monospace;
}
.phone_input_title{
    font-size: 1rem;
    text-align: center;
    color: var(--job);
    font-weight: 600;
    margin-top: 30px;
}
.phone_input{
    outline: none;
    border: none;
    color: var(--neutral);
    font-size: 1.4rem;
    letter-spacing: 32px;
    font-family: 'Roboto Mono', monospace;
    caret-color: var(--job);
    overflow: hidden;
    margin-right: -35px;
    margin-left: 5px;
    z-index: 1;
    background-color: transparent;
}
.phone_input_word{
    outline: none;
    border: none;
    color: var(--neutral);
    letter-spacing: 20px;
    font-size: 1.3rem;
    font-family: 'Roboto Mono', monospace;
    caret-color: var(--red);
    overflow: hidden;
    z-index: 1;
    background-color: transparent;
}
.wrong_code_div{
    display: flex;
    justify-content: center;
    margin-top: 2px;
}
.wrong_code_text{
    font-weight: 400;
    color: var(--yellow);
    text-align: center;
}

.recaptcha_container{
    position: absolute;
    margin-top: 110px;
}

.email{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid var(--yellow); */
    padding: 10px 0px;
    border-radius: 3px;
}
.email_flex{
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: auto;
}
.email_input_title{
    color: var(--neutral);
    margin-top: 5px;
    margin-bottom: 2px;
}
.email_input_icon{
    color: white;
    margin-right: 5px;
}
.email_input{
    padding: 10px;
    outline: none;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
}
.email_input:disabled{
    font-style: italic;
    margin-top: 10px;
    color: #ffffff90;
    font-weight: 400;
    background-color: var(--grey);
    border: none;
}
.email_input::placeholder{
    font-style: normal;
    color: #7d7d7d;
}
.email_error{
    color: var(--red);
    /* margin-left: 50px; */
    margin-top: -5px;
    font-style: italic;
}
.show_flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.o2_button{
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    border: 3px solid var(--brand);
    border-radius: 10px;
    width: 90%;
    margin: auto;
    cursor: pointer;
    background-color: white;
    margin-top: 20px;
}
.o2_button:hover{
    opacity: 0.7;
    transition: 150ms all;
}
.o2_img{
    height: 25px;
    width: 25px;
}
.o2_text{
    font-weight: 600;
    width: 200px;
    margin-left: 10px;
    font-size: 1.1rem;
}
.area_show_letters{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    color: var(--neutral);
}
.area_show_letters_value{
    font-size: 0.9rem;
    text-decoration: underline;
    color: var(--job);
    font-weight: 600;
}
.area_show_letters_value_no{
    font-size: 0.9rem;
    font-weight: 600;
}
.main_inner_multibanco{
    background-color: var(--neutral);
    padding: 10px 50px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.multibanco_days{
    margin: 10px 0 20px 0;
    font-weight: 500;
    text-align: center;
}
.multibanco_img_wrapper{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.multibanco_img_val{
    width: 58px !important;
    height: 70px !important;
}
.multibanco_item{
    display: flex;
    align-self: center;
}
.multibanco_item_wrapper{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-bottom: 10px;
}
.multibanco_item_title{
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 15px;
    font-size: 1rem;
}
.multibanco_item_value{
    font-weight: 600;
    margin-top: 12px;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.1rem;
}
.expires{
    margin-top: 10px;
    font-style: italic;
    font-size: 0.9rem;
}
@media (max-width: 500px){
    .main{
        width: 80%;
    }
    .title{
        font-size: 0.9rem;
    }
    .phone_description{
        font-size: 0.8rem;
    }
    .button{
        width: 90%;
    }
    .button_text{
        font-size: 0.9rem;
    }
    .cancel{
        font-size: 0.8rem;
    }
    .phone_value{
        font-size: 1rem;
    }
    .phone_prefix{
        font-size: 1rem;
    }
    .button_text{
        font-size: 0.8rem;
        padding: 0 3px;
        width: max-content;
    }
    .button_disabled{
        width: 80%;
    }
    .resend{
        width: 80%;
    }
    .phone_input_title{
        font-size: 0.8rem;
        margin-top: 5px;
    }
    .phone_input_wrapper{
        margin-bottom: 5px;
    }
    .multibanco_days{
        font-size: 0.9rem;
    }
    .multibanco_img_val{
        width: 35px !important;
        height: 40px !important;
    }
    .multibanco_item_title{
        font-size: 0.8rem;
    }
    .multibanco_item_value{
        font-size: 0.9rem;
    }
    .expires{
        font-size: 0.8;
    }
}