@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

.welcome_wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--brand);
    z-index: 8;
}
.welcome{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9;
    overflow-x: hidden;
    /* overflow-y: hidden; */
    min-height: 1000px;
}
.main{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 11;
    /* min-height: 1200px; */
}
.image_main{
    position: absolute;
    top: 0;
    left: 50%;
}
.image_main_small{
    position: absolute;
    top: 0;
    left: 50%;
    display: none;
}
.image{
    position: absolute;
    z-index: 11;
    opacity: 0.1;
    transform: scale(0.3);
}
.main_top{
    background: rgb(56,64,71);
    background: linear-gradient(180deg, rgba(56,64,71,1) 0%, rgba(22,31,40,1) 25%, rgba(3,5,6,1) 100%);
    margin-top: 0px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.main_top_wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -200px;
    /* width: 100%; */
}
.main_bot{
    background: rgb(3,5,6);
    background: linear-gradient(180deg, rgba(3,5,6,1) 38%, rgba(49,31,29,1) 61%, rgba(82,47,42,1) 80%, rgba(151,80,68,1) 100%, rgba(200,99,78,1) 100%, rgba(255,120,90,1) 100%);
    flex: 1 auto;
    position: relative;
}
.main_bot_absolute{
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 300px;
    z-index: 13;
}
.main_bot_wrapper{
    /* position: absolute; */
    left: 0;
    top: 0;
    width: 100%;
    /* padding-top: 120px; */
    margin-top: 10px;
    z-index: 13;

}
.sub_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0px 3px 0px;
    /* border-top: 1px solid var(--grey); */
    /* border-bottom: 1px solid var(--grey); */
}
.sub_sub_main{
    /* background-color: var(--neutral); */
    border-radius: 5px;
    padding: 10px;
    width: max-content;
    margin: 0 auto;
}
.title{
    color: white;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 30px;
}
.sub_title_title{
    color: var(--neutral);
    font-size: 1rem;
    margin-top: -10px;
    margin-bottom: 10px;
}
.sub_title{
    color: var(--neutral);
    /* text-decoration: underline; */
    text-decoration-color: var(--job);
    text-decoration-thickness: 2px;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 auto;
    width: fit-content;
    margin-top: 15px;
    /* background-color: var(--neutral);
    padding: 5px;
    border-radius: 3px; */
}
.sub_title_caveat{
    font-family: "Caveat", cursive;
    color: var(--neutral);
    font-size: 1.9rem;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
}
.sub_title_special_job{
    color: var(--job);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.2rem !important;
}
.sub_title_special_action{
    color: var(--action);
    font-weight: 700;
    text-transform: uppercase;
}
.sub_title_separator{
    text-align: center;
    margin: 0px;
    color: var(--job);
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
    margin-top: -30px;
    margin-bottom: 40px;
}
.main_inner{
    display: flex;
    justify-content: center;
}
.main_top_wrapper_2{
    height: 60px;
}
.button{
    padding: 20px 25px;
    border-radius: 5px;
    background-color: var(--job);
    font-weight: 600;
    cursor: pointer;
    width: max-content;
    font-weight: 600;
    color: var(--neutral);
    text-align: center;
    font-size: 1.1rem;
    transition: 150ms all;
    margin: auto;
    margin-top: 30px;
    position: absolute;
    z-index: 13;
}
.button:hover{
    background-color: var(--neutral);
    color: var(--job);
}
.text_brand{
    width: 350px;
    /* height: 144px; */
    margin: auto;
    /* margin-bottom: 50px; */
}
.button_wrapper{
    margin: auto;
    margin-top: 0px;
    transition: 150ms all;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.button_text{
    /* text-transform: uppercase; */
    /* font-family: "Caveat", cursive; */
    font-size: 1.2rem;
}
.button_worker{
    padding: 20px 25px;
    border-radius: 5px;
    background-color: var(--action);
    font-weight: 600;
    cursor: pointer;
    width: max-content;
    font-weight: 600;
    color: var(--neutral);
    text-align: center;
    font-size: 1.1rem;
    transition: 150ms all;
    margin: 0 auto;
    margin-top: 30px;
}
.button_worker:hover{
    background-color: var(--neutral);
    color: var(--action);
}
.button_separator{
    width: 100%;
    height: 10px;
    background-color: var(--neutral);
    opacity: 0.99;
    /* margin: 70px 0; */
    margin-top: -100px;
    background-image: url('../assets/logo_full_brand.png');
    background-position: left -50px top 0px; 
}

@media (max-width: 850px){
    .button_wrapper{
        margin-top: 20px;
    }
}
@media (max-width: 500px){
    .welcome{
        height: 100vh;
        min-height: 700px;
    }
    .button{
        padding: 10px 10px;
        margin-top: 10px;
    }
    .button_worker{
        padding: 10px 10px;
        margin-top: 20px;
    }
    .button_separator{
        margin-top: -250px;
    }
    .sub_title{
        font-size: 0.9rem;
        width: 90%;
    }
    .sub_title_caveat{
        font-size: 1.5rem;
    }
    .sub_title_special_job{
        font-size: 0.8rem !important;
    }
    .sub_title_special_action{
        font-size: 0.8rem !important;
    }
    .button_text{
        font-size: 0.8rem;
    }
    .text_brand{
        width: 240px;
        margin-bottom: 20px;
    }
    .sub_title_separator{
        font-size: 0.7rem;
        margin-top: -25px;
    }
    .main{
        min-height: 800px;
    }
    .main_top{
        flex: 1;
        padding-top: 20px;
        padding-bottom: 130px;
    }
    .main_bot{
        flex: 1;
    }
    .image_main{
        display: none;
    }
    .image_main_small{
        display: block;
    }
}