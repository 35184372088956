.suporte{
    color: var(--brand);
    height: calc(100vh - 68px);
    overflow-y: hidden;
}
.suporte_title{
    width: 100%;
    display: flex;
    min-height: 80px;
    background-color: white;
    margin-top: 1px;
}
.top_title_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    background-color: var(--brand);
}
.top_title{
    font-size: 1.2rem;
    color: var(--neutral);
    font-weight: 600;
    align-self: center;
    text-transform: uppercase;
}
.top_title_short{
    font-size: 1.2rem;
    color: var(--neutral);
    font-weight: 600;
    align-self: center;
    text-transform: uppercase;
    display: none;
}
.main{
    display: flex;
    width: 100%;
    height: 100%;
}
.users{
    /* border-top: 1px solid #ccc; */
    padding-top: 0px;
    width: 200px;
    background-color: white;
}
.chat{
    flex-grow: 1;
    min-height: calc(100%);
    position: relative;
}
.chat_wrapper{
    min-height: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: clip;
    position: relative;
}
.inicio_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -5px;
}
.inicio_text{
    color: #ffffff80;
    font-size: 0.8rem;
    font-style: italic;
    padding-top: 10px;
}
.top{
    display: flex;
    min-height: 80px;
    justify-content: space-between;
    background-color: var(--brand);
}
.top_separator_user{
    position: absolute;
    top: 80px;
    z-index: 4;
    width: 100%;
    height: 1px;
    background: rgb(3,88,229);
    background: linear-gradient(270deg, rgba(3,88,229,1) 0%, rgba(72,134,236,1) 43%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
}
.top_separator_worker{
    position: absolute;
    top: 80px;
    z-index: 4;
    width: 100%;
    height: 1px;
    background: rgb(255,120,90);
    background: linear-gradient(90deg, rgba(255,120,90,1) 0%, rgba(255,120,90,1) 43%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
}
.top_separator_worker_reservation{
    position: absolute;
    top: 80px;
    z-index: 4;
    width: 100%;
    height: 1px;
    background: rgb(255,120,90);
    background: linear-gradient(90deg, rgba(255,120,90,1) 0%, rgba(3,88,229,1) 100%);
}
.top_left_flex_for_user{
    display: flex;
    margin-left: 10px;
    align-self: center;
    align-items: center;
}
.top_left_flex_for_user:hover{
    opacity: 0.6;
    cursor: pointer;
}
.top_left_flex{
    display: flex;
    margin-left: 10px;
    align-self: center;
    align-items: center;
}
.top_left_indicator{
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: var(--red);
}
.top_left_photo{
    width: 40px !important;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 10px;
}
.top_left_name{
    font-size: 1.3rem;
    font-weight: 700;
    color: white;
}
.chat_area{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    background-color: var(--brand);
    position: relative;
}
.chat_area_scroll{
    height: 10px;
}
.chatbox_wrapper{
    background-color: var(--brand);
}
.chatbox_wrapper_send{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 5px 0;
}
.chatbox_wrapper_receive{
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
    max-width: 100px;
}
.send{
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10px;
    width: fit-content;
}
.receive{
    display: flex;
}
.chatbox_user{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    margin-bottom: -20px;
}
.chatbox_user_opacity{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-bottom: -10px;
    opacity: 0;
}
.send:hover > .chatbox_user_opacity{
    opacity: 0.5;
}
.receive:hover > .chatbox_user_opacity{
    opacity: 0.5;
}
.chatbox_user_img{
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    color: white;
}
.chatbox_user_img_small{
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    color: white;
    margin: 0 5px;
}
.chatbox_user_timestamp{
    font-size: 0.9rem;
    color: white;
}
.chatbox_user_timestamp_small{
    font-size: 0.7rem;
    color: white;
}
.chatbox_text_send{
    margin: 0 10px;
    background-color: var(--action);
    color: white;
    height: fit-content;
    padding: 10px 10px;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    width: fit-content;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.chatbox_text_receive{
    margin: 2px 10px;
    background-color: white;
    height: fit-content;
    padding: 10px 10px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}
.chatbox_text_value{
    max-width: 200px;
    min-width: 200px;
    display: inline-block;
    word-wrap:break-word;
    font-size: 0.9rem;
    white-space: pre-line;
}
.chatbox_text_value::selection{
    color: var(--brand);
    background-color: var(--yellow);
}
.chatbox_text_starter{
    margin: 2px 10px;
    background-color: var(--job);
    height: fit-content;
    padding: 10px 10px;
    border-radius: 10px;
    color: white;
}
.chatbot_template{
    font-style: italic;
    font-size: 0.9rem;
    color: #ffffffaa;
}
.chatbox_template_title_wrapper{
    padding: 15px 0;
    display: flex;
    justify-content: center;
    max-width: 190px;
    cursor: pointer;
}
.chatbox_template_title{
    color: var(--neutral);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
}
.chatbox_template_title:hover{
    opacity: 0.8;
}
.type_indicator{
    color: #ccc;
    font-size: 0.8rem;
    margin-left: 2px;
}
.indicator_div{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 2px;
}
.indicator_icon{
    color: var(--action);
    width: 15px !important;
    height: 15px !important;
    transform: scaleX(-1);
}
.indicator_name{
    color: var(--action);
    font-size: 0.8rem;
    font-weight: 500;
}
.name_indicator{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.bot{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 0;
    background-color: var(--brand);
}
.bot_flex{
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.bot_input{
    align-self: center;
    margin-left: 10px;
    font-size: 1.2rem;
    border: none;
    background-color: transparent;
    cursor: text;
    width: 95%;
    font-family: 'Montserrat', sans-serif;
    resize: none;
    max-height: 50px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    display: inline-block;
    background-color: var(--neutral);
    border-radius: 20px;
    padding: 10px 20px;
    overflow-y: scroll;
    font-size: 0.9rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.bot_input:focus{
    outline: none;
}
.bot_right_flex{
    display: flex;
    margin: auto 10px;
    width: 50px;
    align-self: flex-end;
}
.send_icon_worker{
    padding: 10px;
    background-color: var(--action);
    border-radius: 50%;
    color: white;
    transform: rotate(-45deg);
    cursor: default;
}
.send_icon_worker:hover{
    background-color: var(--neutral);
    color: var(--action);
    cursor: pointer;
    transition: all 100ms ease-in-out;
}
.send_icon_user{
    padding: 10px;
    background-color: var(--job);
    border-radius: 50%;
    color: white;
    transform: rotate(-45deg);
    cursor: default;
}
.send_icon_user:hover{
    background-color: var(--neutral);
    color: var(--job);
    cursor: pointer;
    transition: all 100ms ease-in-out;
}
.row{
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    border-bottom: 2px solid var(--job);
}
.row:hover{
    background-color: #0358e520;
}
.row_just_worker{
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    border-bottom: 2px solid var(--action);
}
.row_just_worker:hover{
    background-color: #FF785A20;
}
.row_selected{
    display: flex;
    align-items: center;
    background-color: #0358e520;
    padding: 5px;
    cursor: pointer;
    border-radius: 2px;
    border-bottom: 2px solid var(--job);
}
.row_selected_just_worker{
    display: flex;
    align-items: center;
    background-color: #FF785A20;
    padding: 5px;
    cursor: pointer;
    border-radius: 2px;
    border-bottom: 2px solid var(--action);
}
.row_icon_wrapper{
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--job);
}
.row_icon{
    color: white;
    width: 25px !important;
    height: 25px !important;
}
.row_img{
    min-width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}
.row_main{
    display: flex;
    margin-left: 5px;
    flex-direction: column;
    height: 40px;
    justify-content: center;
    width: 100%;
}
.main_top{
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    align-items: center;
}
.top_name{
    font-weight: 600;
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.top_hour{
    color: #ccc;
    font-size: 0.7rem;

}
.main_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bot_text{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.7rem;
    font-weight: 400;
    color: var(--grey);
    word-wrap:break-word;
    max-width: 120px;
}

.bot_not{

}
.bot_not_icon{
    width: 10px !important;
    height: 10px !important;
    color: var(--action);
}
.post{
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    padding: 5px 20px;
    cursor: pointer;
    justify-content: center;
    background-color: var(--job);
}
.post:hover{
    opacity: 0.8;
}
.post:hover > * {
    opacity: 0.8;
}
.post_title{
    font-weight: 400;
    font-size: 1.2rem;
    align-self: center;
    text-transform: uppercase;
    color: var(--neutral);
}
.post_link{
    color: var(--neutral);
    font-size: 0.7rem;
    text-transform: uppercase;
    cursor: pointer;
    margin: 2px auto;
    font-weight: 700;
}


.day_splitter{
    margin: 25px auto;
    width: 90%;
    height: 1px;
    background-color: #ffffff20;
    position: relative;
}
.day_value{
    position: absolute;
    margin: auto;
    padding: 5px;
    background-color: var(--brand);
    width: 180px;
    text-align: center;
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 15px;
    color: #ffffff40;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
}

@media (max-width: 1024px){
    .item_wrapper{
        font-size: 0.8rem;
    }
}
@media (max-width: 850px){
    .item_wrapper{
        font-size: 0.6rem;
    }
}
@media (min-width: 1025px)
{
    .chatbox_text_value{
        max-width: 300px;
    }
}
@media (min-width: 1450px)
{
    .chatbox_text_value{
        max-width: 400px;
    }
}

@media (max-width: 500px) {
    .suporte{
        height: calc(100vh - 41px);
    }
    .top_title{
        display: none;
    }
    .top_title_short{
        display: block;
    }
    .top_title_wrap{
        padding: 5px 0;
    }
    .row_icon_wrapper{
        width: 20px !important;
        min-width: 20px;
        height: 20px !important;
        display: none;
    }
    .row_icon{
        width: 15px !important;
        height: 15px !important;
    }
    .chatbox_user_img{
        width: 20px !important;
        height: 20px !important;
    }
    .chatbox_user_img_small{
        width: 15px !important;
        height: 15px !important;
        border-radius: 50%;
        color: white;
        margin: 0 2.5px;
    }
    .row_img{
        width: 20px !important;
        min-width: 20px;
        height: 20px !important;
    }
    .top_name{
        font-size: 0.6rem;
    }
    .top_hour{
        display: none;
    }
    .bot_text{
        font-size: 0.5rem;
    }
    .row{
        padding: 5px 5px 5px 0px;
        border-bottom: 1px solid #ccc;
    }
    .row_main{
        height: 20px;
    }
    .main_top{
        margin-bottom: 0px;
    }
    .bot_not{
        height: 10px;
    }
    .bot_not_icon{
        margin-bottom: 4px;
        height: 8px !important;
        width: 8px !important;
    }
    .users{
        width: 50px;
    }
    .top{
        min-height: 40px;
        width: 100%;
    }
    .top_left_flex{
        margin-left: 0px;
    }
    .top_left_flex_for_user{
        margin-left: 0px;
    }
    .top_left_name{
        font-size: 0.8rem;
        width: max-content;
    }
    .indicator_name{
        font-size: 0.6rem;
    }
    .type_indicator{
        margin-left: 0;
    }
    .post{
        margin-left: 0px;
        padding: 5px 5px;
    }
    .post_title{
        font-size: 0.6rem;
        text-align: center;
        display: none;
    }
    .top_separator_user{
        top: 40px;
    }
    .top_separator_worker{
        top: 40px;
    }
    .top_separator_worker_reservation{
        top: 40px;
    }
    .inicio_text{
        font-size: 0.5rem;
    }
    .day_splitter{
        margin: 25px auto;
    }
    .day_value{
        font-size: 0.6rem;
        height: 10px;
        top: 0px;
        width: 120px;
    }
    .chatbox_user_img{
        width: 15px !important;
        height: 15px !important;
    }
    .chatbox_user_img_small{
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;
        color: white;
        margin-left: 5px;
    }
    .chatbox_user_timestamp{
        font-size: 0.5rem;
    }
    .chatbox_user_timestamp_small{
        font-size: 0.3rem;
    }
    .chatbox_text_starter{
        padding: 5px;
        margin: 2px 5px;
    }
    .chatbot_template{
        font-size: 0.6rem;
    }
    .chatbox_template_title_wrapper{
        padding: 5px;
    }
    .chatbox_template_title{
        font-size: 0.7rem;
    }
    .send{
        margin-right: 5px;
    }
    .chatbox_text_send{
        margin-right: 5px;
        padding: 5px 8px;
    }
    .chatbox_text_value{
        font-size: 0.6rem;
        min-width: 150px;
        max-width: 150px;
    }
    .chatbox_text_receive{
        padding: 5px 8px;
        margin-left: 5px;
    }
    .bot{
        padding-bottom: 3px;
    }
    .bot_input{
        margin-left: 5px;
        max-height: 25px !important;
        font-size: 0.6rem;
    }
    .bot_right_flex{
        margin: auto 5px;
        width: 30px;
    }
    .send_icon_user{
        padding: 5px;
        width: 20px !important;
        height: 20px !important;
    }
}


